/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  ATMGrid,
  ATMHeader,
  ORGDataTable,
  IORGDataTableColumn,
  ATMButton,
  MOLInfoText,
} from 'shared-it-appmod-ui';
import Status from 'src/components/atoms/status/status.component';
import { AccessRole, SuspensionEvent, SuspensionStatus } from 'src/constants';
import { getIsAdmin } from 'src/libraries/access.library';
import { getUser } from 'src/libraries/amplify.library';
import Moment, { formatDateTimeSec } from 'src/libraries/moment.library';
import {
  IAuthorizedSuspensionHistory,
  IAuthorizedList,
  IAuthorizedListSuspensionForm,
} from 'src/models/authorized-list.model';
import { removeDoubleZeroEmpId } from 'src/selectors/employee.selector';
import AuthorizedListSuspensionLift from '../authorized-list-suspension-lift/authorized-list-suspension-lift.component';
import AuthorizedListSuspensionSuspend from '../authorized-list-suspension-suspend/authorized-list-suspension-suspended.component';
import style from '../authorized-list-suspension.module.scss';

const columns: IORGDataTableColumn<IAuthorizedSuspensionHistory>[] = [
  {
    index: 'authTypId',
    title: 'Auth Type',
    width: '20%',
  },
  {
    index: 'updatedAt',
    title: 'Event Time',
    width: '20%',
    render: (_, value) => formatDateTimeSec(value.updatedAt),
  },
  {
    index: 'suspEventId',
    title: 'Event',
    width: '20%',
    render: (_, { suspEventId }) =>
      suspEventId === 1
        ? SuspensionEvent.SUSPENDED
        : SuspensionEvent.SUSPENSION_LIFTED,
  },
  {
    index: 'comments',
    title: 'Comments',
    width: '20%',
    render: (_, value) => (
      <div
        style={{
          width: '350px',
          wordBreak: 'break-word',
        }}
      >
        <MOLInfoText transparent limit={50} content={value.comments || ''} />
      </div>
    ),
  },
  {
    index: 'updatedBy',
    title: 'Updated By',
    width: '20%',
    render: (_, value) => removeDoubleZeroEmpId(value.updatedBy ?? ''),
  },
];

type IProps = {
  current?: any;
  authData?: IAuthorizedList;
  data?: IAuthorizedSuspensionHistory[];
  loading: boolean;
  handleDownload: () => void;
  empName?: string;
};

const AuthorizedListSuspensionListView: React.FC<IProps> = ({
  data,
  empName,
  current,
  loading,
  handleDownload,
}) => {
  const transformData = (trainingItem) => {
    const suspensionDetail: IAuthorizedListSuspensionForm = {
      empId: trainingItem?.empId ?? '--',
      authTypId: trainingItem.authTypId ?? '--',
      comments: undefined,
      updatedAt: Moment().utc().toDate(),
      updatedBy: getUser()?.emp_no,
      authListId: trainingItem.authListId ?? null,
      eventTime: Moment().utc().toDate(),
      event: trainingItem.event ?? '--',
      suspEventId: trainingItem.suspEventId ?? undefined,
    };
    return suspensionDetail;
  };

  const columns2: IORGDataTableColumn<any>[] = [
    {
      index: 'authTraining',
      title: 'Authorization Training',
      render: (_, { authTraining }) =>
        authTraining ? (
          <>
            <Status
              bold
              value={
                authTraining.suspendFlag
                  ? SuspensionStatus.SUSPENDED
                  : SuspensionStatus.AUTHORIZED
              }
            />
            {getIsAdmin(AccessRole.GCC_AUTH_LIST_ADMIN) && (!authTraining.suspendFlag ? (
              <div>
                <AuthorizedListSuspensionSuspend
                  data={transformData(authTraining)}
                  authId={authTraining.authListId}
                  empName={empName}
                />
              </div>
            ) : (
              <div>
                <AuthorizedListSuspensionLift
                  data={transformData(authTraining)}
                  authId={authTraining.authListId}
                  empName={empName}
                />
              </div>
            ))}
          </>
        ) : null,
    },
    {
      index: 'fieldSwitching',
      title: 'Field/Line Switching',
      render: (_, { fieldSwitching }) =>
        fieldSwitching ? (
          <>
            <Status
              bold
              value={
                fieldSwitching.suspendFlag
                  ? SuspensionStatus.SUSPENDED
                  : SuspensionStatus.AUTHORIZED
              }
            />
            {getIsAdmin(AccessRole.GCC_AUTH_LIST_ADMIN) && (!fieldSwitching.suspendFlag ? (
              <div>
                <AuthorizedListSuspensionSuspend
                  data={transformData(fieldSwitching)}
                  authId={fieldSwitching.authListId}
                  empName={empName}
                />
              </div>
            ) : (
              <div>
                <AuthorizedListSuspensionLift
                  data={transformData(fieldSwitching)}
                  authId={fieldSwitching.authListId}
                  empName={empName}
                />
              </div>
            ))}
          </>
        ) : null,
    },
    {
      index: 'substationSwitching',
      title: 'Substation Switching',
      render: (_, { substationSwitching }) =>
        substationSwitching ? (
          <>
            <Status
              bold
              value={
                substationSwitching.suspendFlag
                  ? SuspensionStatus.SUSPENDED
                  : SuspensionStatus.AUTHORIZED
              }
            />
            {getIsAdmin(AccessRole.GCC_AUTH_LIST_ADMIN) && (!substationSwitching.suspendFlag ? (
              <div>
                <AuthorizedListSuspensionSuspend
                  data={transformData(substationSwitching)}
                  authId={substationSwitching.authListId}
                  empName={empName}
                />
              </div>
            ) : (
              <div>
                <AuthorizedListSuspensionLift
                  data={transformData(substationSwitching)}
                  authId={substationSwitching.authListId}
                  empName={empName}
                />
              </div>
            ))}
          </>
        ) : null,
    },
    {
      index: 'substationEntry',
      title: 'Substation Entry',
      render: (_, { substationEntry }) =>
        substationEntry ? (
          <>
            <Status
              bold
              value={
                substationEntry.suspendFlag
                  ? SuspensionStatus.SUSPENDED
                  : SuspensionStatus.AUTHORIZED
              }
            />
            {getIsAdmin(AccessRole.GCC_AUTH_LIST_ADMIN) && (!substationEntry.suspendFlag ? (
              <div>
                <AuthorizedListSuspensionSuspend
                  data={transformData(substationEntry)}
                  authId={substationEntry.authListId}
                  empName={empName}
                />
              </div>
            ) : (
              <div>
                <AuthorizedListSuspensionLift
                  data={transformData(substationEntry)}
                  authId={substationEntry.authListId}
                  empName={empName}
                />
              </div>
            ))}
          </>
        ) : null,
    },
  ];

  return (
    <>
      <ATMGrid columns={2}>
        <ATMGrid.Column verticalAlign="middle">
          <ATMHeader as="h3" style={{ margin: '0.5em 0 1em' }}>
            Current Suspension Status
          </ATMHeader>
        </ATMGrid.Column>
      </ATMGrid>

      <div className="admin-content">
        <ORGDataTable
          columns={columns2}
          data={current ?? []}
          loading={loading}
          celled={false}
          style={{ marginRight: '10px' }}
        />
      </div>

      <ATMGrid columns={1}>
        <ATMGrid.Column verticalAlign="middle">
          <div className={style.flexTitleButton}>
            <ATMHeader as="h3" style={{ margin: '0.5em 0 1em' }}>
              Suspension History
            </ATMHeader>
            <ATMButton
              content="Download"
              icon="download"
              secondary
              onClick={() => handleDownload()}
            />
          </div>
        </ATMGrid.Column>
      </ATMGrid>

      <div className="admin-content">
        <ORGDataTable
          columns={columns}
          data={data ?? []}
          loading={loading}
          celled={false}
          sortable
          style={{ marginRight: '10px' }}
        />
      </div>
    </>
  );
};
export default AuthorizedListSuspensionListView;
