import React, { useCallback, useState } from 'react';
import { useContractorContext } from 'src/contexts/contractor.context';
import { IContractor } from 'src/models/contractor.model';
import { getContractorStatus } from 'src/selectors/contractor.selector';
import { contractorActionTypes } from 'src/ducks/contractor.duck';
import history from 'src/history';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import Lang from 'src/libraries/language';
import ContractorDeleteView from './contractor-delete.view';

type IProp = {
  data: IContractor;
};

const ContractorDelete: React.FC<IProp> = ({ data }) => {
  const { state, actions } = useContractorContext();

  const [isOpen, setIsOpen] = useState(false);

  const status = getContractorStatus(
    state,
    contractorActionTypes.CONTRACTOR_DATA_DELETE
  );

  const handleSubmit = useCallback(async () => {
    if (data.empId) {
      const response = await actions.dataDELETE(data.empId);

      if (!response.error) {
        actions.clearList();
        setIsOpen(false);

        ToastSuccess(
          Lang.formatString(
            Lang.MSG_CONTRACTOR_DELETE,
            data.fullName ?? '00000'
          )
        );
        history.push('/auth-list?tab=4');
      } else if (
        response.error.name ===
        'BadRequest'
      ) {
        <span />;
      } else {
        ToastError('Deleted Error!');
      }
    }
  }, [actions, data]);

  return (
    <ContractorDeleteView
      data={data}
      isOpen={isOpen}
      loading={status.fetching}
      handleOpen={setIsOpen}
      handleSubmit={handleSubmit}
    />
  );
};

export default ContractorDelete;
