/* eslint-disable no-lone-blocks */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  ATMButton,
  ATMGrid,
  ATMHeader,
  ATMLoader,
  ATMTable,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { format24hTime, formatDate } from 'src/libraries/moment.library';
import { ILerRequest, ILerRequestOutageDate } from 'src/models/ler-request.model';
import { compareValues } from 'src/selectors/ler-request.selector';
import { get, sortBy } from 'lodash';
import { AccessRole, LERRequestFormStep } from 'src/constants';
import { hasRole } from 'src/libraries/access.library';

type IProp = {
  fields: Array<string>;
  data?: ILerRequest[];
  info?: string;
};

export const checkDateTimeDiff = ({
  data,
  startDate,
  endDate,
  isStartTime = false,
  isStopTime = false,
 } : {
  data: ILerRequestOutageDate[],
  startDate: Date,
  endDate?: Date,
  isStartTime?: boolean,
  isStopTime?: boolean
 }) => {
    let isChangedOrAdded = false;
    const isDateSame = (date) => formatDate(date) === (formatDate(startDate));
    const dateFound = data?.filter((val) => isDateSame(val?.startTm));
    if (!dateFound?.length) {
      return true;
    }
    data?.map((val) => {
      if (isDateSame(val?.startTm)) {
        if (isStartTime) {
          const startTime = format24hTime(val?.startTm);
          isChangedOrAdded = format24hTime(startDate) !== startTime;
        }
        if (isStopTime) {
          const endTime = format24hTime(val?.stopTm);
          isChangedOrAdded = format24hTime(endDate) !== endTime;
        }
      }
    });
    return isChangedOrAdded;
};

const DisplayValues: React.FC<IProp> = ({ fields, data, info }) => {
  if (!info) {
    return (
      <>
        {data?.map((val, index) => {
          return (
            <ATMTable.Cell key={`${index}FI`} verticalAlign="top">
              <p style={{ visibility: 'hidden' }}>
                <b>Hidden Field</b>{' '}
              </p>
              {data &&
                data.length > 0 &&
                fields.map((field, index2) => {
                  return (
                    <p
                      key={`${index}_${index2}`}
                      style={
                        index !== 0 && !Array.isArray(data[index][field])
                          ? compareValues([
                              get(data[index], field),
                              get(data[index - 1], field),
                            ]) === false
                            ? { backgroundColor: 'red', color: 'white' }
                            : {}
                          : index !== 0 && Array.isArray(data[index][field])
                          ? compareValues([
                              sortBy(data[index][field])
                                .map((v) => {
                                  return typeof v[
                                    Lang.LER_VERSION_DISPLAY_FIELDS[field]
                                  ] === 'object'
                                    ? `${
                                        v[
                                          Lang.LER_VERSION_DISPLAY_FIELDS
                                            .category
                                        ].shortDescCat
                                      } - ${
                                        v[
                                          Lang.LER_VERSION_DISPLAY_FIELDS[field]
                                        ].shortDesc
                                      }`
                                    : v[Lang.LER_VERSION_DISPLAY_FIELDS[field]];
                                })
                                .toString(),
                              sortBy(data[index - 1][field])
                                .map((v) => {
                                  return typeof v[
                                    Lang.LER_VERSION_DISPLAY_FIELDS[field]
                                  ] === 'object'
                                    ? `${
                                        v[
                                          Lang.LER_VERSION_DISPLAY_FIELDS
                                            .category
                                        ].shortDescCat
                                      } - ${
                                        v[
                                          Lang.LER_VERSION_DISPLAY_FIELDS[field]
                                        ].shortDesc
                                      }`
                                    : v[Lang.LER_VERSION_DISPLAY_FIELDS[field]];
                                })
                                .toString(),
                            ]) === false
                            ? { backgroundColor: 'red', color: 'white' }
                            : {}
                          : {}
                      }
                    >
                      {' '}
                      {!Array.isArray(data[index][field])
                        ? field === 'requestStat'
                          ? Lang.LER_REQUEST_STATUS[
                              get(data[index], field) ?? 12
                            ]
                          : get(data[index], field) || '-'
                        : val[field]
                            ?.map((v) => {
                              return typeof v[
                                Lang.LER_VERSION_DISPLAY_FIELDS[field]
                              ] === 'object'
                                ? `${
                                    v[Lang.LER_VERSION_DISPLAY_FIELDS.category]
                                      .shortDescCat
                                  } - ${
                                    v[Lang.LER_VERSION_DISPLAY_FIELDS[field]]
                                      .shortDesc
                                  }`
                                : v[Lang.LER_VERSION_DISPLAY_FIELDS[field]];
                            })
                            .toString() || '-'}{' '}
                    </p>
                  );
                })}
            </ATMTable.Cell>
          );
        })}
      </>
    );
  }
  return (
    <>
      {data?.map((val, index) => {
        return (
          <ATMTable.Cell key={`${index}FI`} verticalAlign="top" width="3">
            <p style={{ visibility: 'hidden' }}>
              <b>Hidden Field</b>{' '}
            </p>
            {data &&
              data.length > 0 &&
              fields.map((field, index2) => {
                return (
                  <p
                    key={`${index}_${index2}`}
                    style={
                      index !== 0 && !Array.isArray(data[index][info][field])
                        ? compareValues([
                            get(data[index], `${info}.${field}`),
                            get(data[index - 1], `${info}.${field}`),
                          ]) === false
                          ? { backgroundColor: 'red', color: 'white' }
                          : {}
                        : index !== 0 && Array.isArray(data[index][info][field])
                        ? compareValues([
                            sortBy(data[index][info][field])
                              .map((v) => {
                                return typeof v[
                                  Lang.LER_VERSION_DISPLAY_FIELDS[field]
                                ] === 'object'
                                  ? `${
                                      v[
                                        Lang.LER_VERSION_DISPLAY_FIELDS.category
                                      ].shortDescCat
                                    } - ${
                                      v[Lang.LER_VERSION_DISPLAY_FIELDS[field]]
                                        .shortDesc
                                    }`
                                  : v[Lang.LER_VERSION_DISPLAY_FIELDS[field]];
                              })
                              .toString(),
                            sortBy(data[index - 1][info][field])
                              .map((v) => {
                                return typeof v[
                                  Lang.LER_VERSION_DISPLAY_FIELDS[field]
                                ] === 'object'
                                  ? `${
                                      v[
                                        Lang.LER_VERSION_DISPLAY_FIELDS.category
                                      ].shortDescCat
                                    } - ${
                                      v[Lang.LER_VERSION_DISPLAY_FIELDS[field]]
                                        .shortDesc
                                    }`
                                  : v[Lang.LER_VERSION_DISPLAY_FIELDS[field]];
                              })
                              .toString(),
                          ]) === false
                          ? { backgroundColor: 'red', color: 'white' }
                          : {}
                        : {}
                    }
                  >
                    {' '}
                    {!Array.isArray(data[index][info][field])
                      ? field.includes('.firstName')
                        ? get(data[index][info], field)
                          ? `${get(data[index][info], field)} ${get(
                              data[index][info],
                              `${field.replace('.firstName', '.lastName')}`
                            )}`
                          : '-'
                        : typeof data[index][info][field] === 'boolean' ||
                          field.includes('authorized')
                        ? data[index][info][field]
                          ? Lang.LBL_YES
                          : Lang.LBL_NO
                        : get(data[index][info], field) || '-'
                      : val[info][field]
                          ?.map((v) => {
                            return typeof v[
                              Lang.LER_VERSION_DISPLAY_FIELDS[field]
                            ] === 'object'
                              ? `${
                                  v[Lang.LER_VERSION_DISPLAY_FIELDS.category]
                                    .shortDescCat
                                } - ${
                                  v[Lang.LER_VERSION_DISPLAY_FIELDS[field]]
                                    .shortDesc
                                }`
                              : v[Lang.LER_VERSION_DISPLAY_FIELDS[field]];
                          })
                          .toString() || '-'}{' '}
                  </p>
                );
              })}
          </ATMTable.Cell>
        );
      })}
    </>
  );
};

type IProps = {
  data?: ILerRequest[];
  setIsCompare: React.Dispatch<React.SetStateAction<boolean>>;
  setIsChecked: React.Dispatch<React.SetStateAction<Array<string> | undefined>>;
  loading: boolean;
  handleEnable: React.Dispatch<React.SetStateAction<boolean>>;
};

const LerRequestVersionDetail: React.FC<IProps> = ({
  data,
  setIsCompare,
  setIsChecked,
  loading,
  handleEnable,
}) => {
  const isAllowedToViewISO = hasRole(AccessRole.MCC_OUTAGE_SCHEDULER);
  return (
    <ATMGrid>
      <ATMGrid.Row columns={2}>
        <ATMGrid.Column verticalAlign="middle">
          <ATMHeader as="h2">{Lang.TTL_LER_REQUEST_VERSION}</ATMHeader>
        </ATMGrid.Column>
        <ATMGrid.Column verticalAlign="middle" textAlign="right">
          <ATMButton
            secondary
            content={Lang.LBL_GO_BACK}
            type="button"
            onClick={() => {
              setIsCompare(false);
              setIsChecked(undefined);
              handleEnable(false);
            }}
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
      <ATMGrid.Row columns={1}>
        <ATMGrid.Column width={loading || (data && data.length >= 3) ? 16 : 12}>
          {loading ? (
            <ATMLoader inline="centered" active />
          ) : (
            <ATMTable celled enableScroll stickyHeader fixedColumns="first">
              <ATMTable.Header>
                <ATMTable.Row>
                  <ATMTable.HeaderCell>
                    {Lang.LBL_CHANGE_REQUEST_FIELD}
                  </ATMTable.HeaderCell>
                  {data?.map((val, i) => {
                    return (
                      <ATMTable.HeaderCell key={i}>
                        {Lang.formatString(
                          Lang.LBL_VERSION_NO_HEADER,
                          val.version_no ?? '1'
                        )}
                      </ATMTable.HeaderCell>
                    );
                  })}
                </ATMTable.Row>
              </ATMTable.Header>
              <ATMTable.Body>
                {/* Facility Information */}
                <ATMTable.Row>
                  <ATMTable.Cell width={2} textAlign="right">
                    <p>
                      <b>{Lang.LBL_FACILITY_INFORMATION}</b>{' '}
                    </p>
                    <p>{Lang.LBL_OUTAGE_TYPE}</p>
                    <p>{Lang.LBL_FACILITY_NAME}</p>
                    <p>{Lang.LBL_EQUIPMENT_TYPE}</p>
                    <p>{Lang.LBL_SUBSTATION}</p>
                    <p>{Lang.LBL_VOLTAGE}</p>
                  </ATMTable.Cell>
                  <DisplayValues
                    fields={
                      Lang.LER_VERSION_DISPLAY_FIELDS[
                        LERRequestFormStep.OUTAGE_FACILITY
                      ]
                    }
                    data={data}
                    info={LERRequestFormStep.OUTAGE_FACILITY}
                  />
                </ATMTable.Row>
                <ATMTable.Row>
                  <ATMTable.Cell
                    textAlign="right"
                    verticalAlign="top"
                    width={2}
                  >
                    <p>
                      <b>{Lang.TTL_OUTAGE_DATES}</b>{' '}
                    </p>
                    <p>{Lang.LBL_DATE}</p>
                  </ATMTable.Cell>
                  {data?.map((val, index) => {
                    return (
                      <ATMTable.Cell key={`${index}OD`} verticalAlign="top">
                        <p style={{ visibility: 'hidden' }}>
                          <b>{Lang.TTL_OUTAGE_DATES}</b>{' '}
                        </p>
                        {val.outageDates.map((item, i) => {
                          return (
                            <p key={i}>
                              <span
                                style={
                                  index !== 0
                                    ? checkDateTimeDiff(
                                      { data: data[index - 1]?.outageDates, startDate: item?.startTm as any })
                                      ? {
                                          backgroundColor: 'red',
                                          color: 'white',
                                        }
                                      : {}
                                    : {}
                                }
                              >
                                {formatDate(item.startTm)}{' '}
                              </span>
                              <span
                                style={
                                  index !== 0
                                    ? checkDateTimeDiff(
                                      { data: data[index - 1]?.outageDates, startDate: item?.startTm as any, isStartTime: true })
                                      ? {
                                          backgroundColor: 'red',
                                          color: 'white',
                                        }
                                      : {}
                                    : {}
                                }
                              >
                                {format24hTime(item.startTm)}
                              </span>
                              <span
                                style={
                                  index !== 0
                                    ? checkDateTimeDiff(
                                      { data: data[index - 1]?.outageDates, startDate: item?.startTm as any }) && checkDateTimeDiff(
                                      { data: data[index - 1]?.outageDates, startDate: item?.startTm as any, isStartTime: true })
                                      ? {
                                          backgroundColor: 'red',
                                          color: 'white',
                                        }
                                      : {}
                                    : {}
                                }
                              >
                                {' '}-{' '}
                              </span>
                              <span
                                style={
                                  index !== 0
                                    ? checkDateTimeDiff({ data: data[index - 1]?.outageDates, startDate: item?.startTm as any, endDate: item?.stopTm as any, isStopTime: true })
                                      ? {
                                          backgroundColor: 'red',
                                          color: 'white',
                                        }
                                      : {}
                                    : {}
                                }
                              >
                                {format24hTime(item.stopTm)}
                              </span>
                            </p>
                          );
                        })}
                      </ATMTable.Cell>
                    );
                  })}
                </ATMTable.Row>
                {/* Job Information */}
                <ATMTable.Row>
                  <ATMTable.Cell width={2} textAlign="right">
                    <p>
                      <b>{Lang.TTL_JOB_INFO}</b>{' '}
                    </p>
                    <p>{Lang.LBL_SWITCH_IN}</p>
                    <p>{Lang.LBL_SWITCH_OUT}</p>
                    <p>{Lang.LBL_SHORT_DESCRIPTION}</p>
                    <p>{Lang.LBL_RECALL_TIME}</p>
                    <p>{Lang.LBL_JOB_INFO_JOB_ID}</p>
                    <p>{Lang.LBL_WORK_ORDER_NUMBER}</p>
                    <p>{Lang.LBL_WORK_DESC}</p>
                    <p>{Lang.LBL_JOB_LOC}</p>
                    <p>{Lang.LBL_NOTES}</p>
                    <p>{Lang.LBL_RADIALIZED_SUBS}</p>
                    <p>{Lang.LBL_RIMS_PROJECT_PHASE}</p>
                    <p>{Lang.LBL_TB_COORDINATE}</p>
                    <p>{Lang.LBL_GROUND_STATEMENT}</p>
                    <p>{Lang.LBL_TEST_VOLTAGE}</p>
                  </ATMTable.Cell>
                  <DisplayValues
                    fields={
                      Lang.LER_VERSION_DISPLAY_FIELDS[
                        LERRequestFormStep.JOB_INFORMATION
                      ]
                    }
                    data={data}
                    info={LERRequestFormStep.JOB_INFORMATION}
                  />
                </ATMTable.Row>
                {/* Crew Information */}
                <ATMTable.Row>
                  <ATMTable.Cell width={2} textAlign="right">
                    <p>
                      <b>{Lang.TTL_CREW_INFO}</b>{' '}
                    </p>
                    <p>{Lang.LBL_CREW_INFO_NAME}</p>
                    <p>{Lang.LBL_INFO_CELLPHONE_NO}</p>
                    <p>{Lang.LBL_CREW_INFO_PAGER}</p>
                    <p>{Lang.LBL_CREW_INFO_TRUCK}</p>
                    <p>{Lang.LBL_OUTAGE_REQUESTOR_NAME}</p>
                    <p>{Lang.LBL_REQUESTOR_EMAIL}</p>
                    <p>{Lang.LBL_AUTHORIZED}</p>
                    <p>{Lang.LBL_CONTACT_NUMBER}</p>
                    <p>{Lang.LBL_REQUESTOR_SUPERVISOR}</p>
                  </ATMTable.Cell>
                  <DisplayValues
                    fields={
                      Lang.LER_VERSION_DISPLAY_FIELDS[
                        LERRequestFormStep.CREW_INFORMATION
                      ]
                    }
                    data={data}
                    info={LERRequestFormStep.CREW_INFORMATION}
                  />
                </ATMTable.Row>
                {/* Auth Statement */}
                <ATMTable.Row
                  style={!isAllowedToViewISO ? { display: 'none' } : {}}
                >
                  <ATMTable.Cell width={2} textAlign="right">
                    <p>
                      <b>{Lang.TTL_AUTHORIZATION_INFORMATION}</b>{' '}
                    </p>
                    <p>{Lang.LBL_OPERATING_NOTE}</p>
                    <p>{Lang.LBL_CANCELLATION_NOTE}</p>
                    <p>{Lang.LBL_DAILY_OUTAGE_REPORT_NOTES}</p>
                  </ATMTable.Cell>
                  <DisplayValues
                    fields={
                      Lang.LER_VERSION_DISPLAY_FIELDS[
                        LERRequestFormStep.AUTHORIZATION_STATEMENT
                      ]
                    }
                    data={data}
                    info={LERRequestFormStep.AUTHORIZATION_STATEMENT}
                  />
                </ATMTable.Row>
                {/* ISO */}
                <ATMTable.Row
                  style={!isAllowedToViewISO ? { display: 'none' } : {}}
                >
                  <ATMTable.Cell width={2} textAlign="right">
                    <p>
                      <b>{Lang.TTL_ISO}</b>{' '}
                    </p>
                    <p>{Lang.LBL_NATURE_OF_WORK}</p>
                  </ATMTable.Cell>
                  <DisplayValues
                    fields={
                      Lang.LER_VERSION_DISPLAY_FIELDS[LERRequestFormStep.ISO]
                    }
                    data={data}
                    info={LERRequestFormStep.ISO}
                  />
                </ATMTable.Row>
                {/* Other Details  */}
                <ATMTable.Row>
                  <ATMTable.Cell width={2} textAlign="right">
                    <p>
                      <b>{Lang.TTL_OTHER_DETAILS}</b>{' '}
                    </p>
                    <p>{Lang.LBL_CANCEL_TYPE}</p>
                    <p>{Lang.LBL_CANCEL_BY}</p>
                    <p>{Lang.LBL_REASON_OF_REJECTION}</p>
                    <p>{Lang.LBL_REJECTED_BY}</p>
                    {/* <p>{Lang.LBL_REASON_OF_UPDATE}</p> */}
                    <p>{Lang.LBL_UPDATED_BY}</p>
                    {/* US #605811 10/02/2023 Date Modified on Version History */}
                    <p>{Lang.LBL_DATE_MODIFIED}</p>
                    <p>{Lang.LBL_STATUS}</p>
                    <p>{` Change Request ${Lang.LBL_STATUS}`}</p>
                  </ATMTable.Cell>
                  <DisplayValues
                    fields={Lang.LER_VERSION_DISPLAY_FIELDS.otherDetails}
                    data={data}
                  />
                </ATMTable.Row>
              </ATMTable.Body>
            </ATMTable>
          )}
        </ATMGrid.Column>
      </ATMGrid.Row>
    </ATMGrid>
  );
};

export default LerRequestVersionDetail;
