/* eslint-disable no-console */
import React from 'react';
import * as Contexts from 'src/contexts';
import ToasterContainer from './components/atoms/toaster/toaster.component';

const App: React.FC = ({ children }) => {
  // console.log('Process Environment:', process.env);
  // console.log('REACT_APP_ENVIRONMENT', process.env.REACT_APP_ENVIRONMENT);
  // console.log(
  //   'REACT_APP_API_ENDPOINT_COMMON',
  //   process.env.REACT_APP_API_ENDPOINT_COMMON
  // );
  // console.log('REACT_APP_API_ENDPOINT', process.env.REACT_APP_API_ENDPOINT);
  // console.log(
  //   'REACT_APP_API_SUBSCRIPTION_KEY',
  //   process.env.REACT_APP_API_SUBSCRIPTION_KEY
  // );
  // console.log('REACT_APP_COGNITO_REGION', process.env.REACT_APP_COGNITO_REGION);
  // console.log(
  //   'REACT_APP_COGNITO_POOL_ID',
  //   process.env.REACT_APP_COGNITO_POOL_ID
  // );
  // console.log(
  //   'REACT_APP_COGNITO_CLIENT_ID',
  //   process.env.REACT_APP_COGNITO_CLIENT_ID
  // );
  // console.log('REACT_APP_COGNITO_DOMAIN', process.env.REACT_APP_COGNITO_DOMAIN);
  // console.log(
  //   'REACT_APP_COGNITO_TOKEN_SCOPE',
  //   process.env.REACT_APP_COGNITO_TOKEN_SCOPE
  // );

  // Combines all the context providers
  const childrenWithContext = Object.keys(Contexts).reduce((child, key) => {
    const ContextProvider = Contexts[key];

    return <ContextProvider>{child}</ContextProvider>;
  }, children);

  return (
    <>
      {childrenWithContext}
      <ToasterContainer />
    </>
  );
};

export default App;
