import React, { useEffect, useCallback } from 'react';
import { addDoubleZeroEmpId } from 'src/selectors/employee.selector';
import Moment from 'src/libraries/moment.library';
import { useAuthorizedListContext } from 'src/contexts/authorized-list.context';
import { authorizedListActionTypes } from 'src/ducks/authorized-list.duck';
import { IAuthorizedList } from 'src/models/authorized-list.model';
import { getAuthorizedListStatus } from 'src/selectors/authorized-list.selector';
import { buildExportDataWithTableTitle, convertToCSV } from 'src/selectors/file.selector';
import { checkValue } from 'src/libraries/common.library';
import Lang from 'src/libraries/language';
import { SuspensionEvent } from 'src/constants';
import AuthorizedListSuspensionListView from './authorized-list-suspension-list.view';

type IProps = {
  data?: IAuthorizedList;
  current?: any;
  empName?: string;
};

const AuthorizedListSuspensionList: React.FC<IProps> = ({ data, current }) => {
  const { state, actions } = useAuthorizedListContext();
  const status = getAuthorizedListStatus(
    state,
    authorizedListActionTypes.AUTHORIZED_SUSPENSION_LIST_HISTORY
  );

  useEffect(() => {
    const param = {
      limit: 0,
      page: 1,
      filters: [
        {
          name: 'empId',
          value: addDoubleZeroEmpId(data?.empId ?? ''),
        },
      ],
    };
    actions.suspensionHistoryListGET(param);
  }, [actions, data]);

  const handleDownload = useCallback(() => {
    if (state.suspensionList) {
      const list = state.suspensionList;
      const items = list.map((value) => [
        checkValue(value.authTypId),
        checkValue(
          Moment(value.updatedAt).format('YYYY-MM-DD HH:mm:ss') ?? '--'
        ),
        checkValue(
          value.suspEventId === 1
            ? SuspensionEvent.SUSPENDED
            : SuspensionEvent.SUSPENSION_LIFTED
        ),
        checkValue(value.comments ?? '--'),
        checkValue(value.updatedBy),
      ]);
      const employeeInfo = `${data?.fullName} / ${
        data?.empId ? data?.empId : data?.contracShortNm
      }`;
      const { exportData, format } = convertToCSV(
        buildExportDataWithTableTitle(
          items,
          [
            Lang.LBL_AUTH_TYPE,
            Lang.LBL_EVENT_TIME,
            Lang.LBL_EVENT,
            Lang.LBL_COMMENTS,
            Lang.LBL_UPDATED_BY,
          ],
          employeeInfo
        )
      );
      const link = document.createElement('a');
      link.setAttribute('href', exportData);
      link.setAttribute(
        'download',
        `MCC_Suspension_History_${data?.fullName}_${Moment().format(
          'YYYYMMDD'
        )}.${format}`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [actions, state]);
  return (
    <AuthorizedListSuspensionListView
      current={current}
      authData={data}
      data={state.suspensionList}
      loading={status.fetching}
      handleDownload={handleDownload}
      empName={data?.fullName ?? ''}
    />
  );
};

export default AuthorizedListSuspensionList;
