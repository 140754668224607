import { Amplify, Hub } from '@aws-amplify/core';
import { Auth, CognitoUser } from '@aws-amplify/auth';

export type IAuthUser = {
  e_mail_address?: string;
  emp_no: string;
  first_name: string;
  last_name: string;
};

const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID ?? '';

const requiredEnv = [
  'REACT_APP_COGNITO_REGION',
  'REACT_APP_COGNITO_POOL_ID',
  'REACT_APP_COGNITO_CLIENT_ID',
  'REACT_APP_COGNITO_DOMAIN',
  'REACT_APP_COGNITO_TOKEN_SCOPE',
];

requiredEnv.forEach((variable) => {
  if (!process.env[variable] || (process.env[variable] ?? '').includes('#%{')) {
    // eslint-disable-next-line no-alert
    alert(
      `Environment Variable Error: Please configure ${variable} properly in .env or .env.local file`
    );
  }
});

const config = {
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION ?? '',
    userPoolId: process.env.REACT_APP_COGNITO_POOL_ID ?? 'us-west-2_TEST12345',
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID ?? '',
    cookieStorage: {
      path: '/',
      expires: '',
      domain: window.location.hostname,
      secure: true,
    },
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN ?? '',
      scope: [
        process.env.REACT_APP_COGNITO_TOKEN_SCOPE ?? '',
        'email',
        'openid',
        'profile',
      ],
      redirectSignIn: window.location.origin,
      redirectSignOut: `${window.location.origin}/logout`,
      responseType: 'code',
    },
  },
};

Amplify.configure(config);

const clearStorage = () => {
  localStorage.removeItem(`${clientId}_user_data`);
  localStorage.removeItem(`${clientId}_user_token`);
};

export const redirect = () => {
  const url = localStorage.getItem(`${clientId}_url`);
  const attempt = localStorage.getItem(`${clientId}_attempt`);
  // Auth.federatedSignIn({
  //   provider: CognitoHostedUIIdentityProvider.Cognito,
  // });

  if (
    (url && url.includes('unauthorized')) ||
    (attempt && Number(attempt) > 3)
  ) {
    localStorage.removeItem(`${clientId}_url`);
    localStorage.removeItem(`${clientId}_attempt`);
    window.location.href = `${window.location.origin}/logout`;
    return;
  }

  localStorage.setItem(
    `${clientId}_url`,
    window.location.href.replace(/logout.*/, '')
  );

  clearStorage();

  // Bypass cognito hosted UI
  window.location.href = `https://${
    config.Auth.oauth.domain
  }/oauth2/authorize?redirect_uri=${
    config.Auth.oauth.redirectSignIn
  }&response_type=CODE&client_id=${
    config.Auth.userPoolWebClientId
  }&scope=${config.Auth.oauth.scope.join(' ')}`;
};

export const logout = async (callback?: () => void) => {
  clearStorage();
  localStorage.removeItem(`${clientId}_url`);
  localStorage.removeItem(`${clientId}_attempt`);

  await Auth.signOut();

  if (callback) {
    callback();
  }
};

export const setUser = (user: IAuthUser) => {
  localStorage.setItem(`${clientId}_user_data`, JSON.stringify(user));
};
export const getUser = () => {
  const user = localStorage.getItem(`${clientId}_user_data`);

  return user ? (JSON.parse(user) as IAuthUser) : null;
};

export const getToken = () => {
  return localStorage.getItem(`${clientId}_user_token`);
};

const setToken = (token: string) => {
  localStorage.setItem(`${clientId}_user_token`, token);
};

export const refreshToken = async () => {
  try {
    const user: CognitoUser = await Auth.currentAuthenticatedUser();

    if (user) {
      const signInUserSession = await user.getSignInUserSession();

      if (signInUserSession) {
        const token = signInUserSession.getRefreshToken();

        return await new Promise<boolean>((resolve) => {
          user.refreshSession(token, (err, session) => {
            if (err) {
              logout();
              resolve(false);
            } else {
              user.setSignInUserSession(session);
              setToken(session.getAccessToken().getJwtToken());
              resolve(true);
            }
          });
        });
      }
    }

    return false;
  } catch (e: any) {
    return false;
  }
};

export const hasAuth = async () => {
  const user = await Auth.currentUserInfo();

  if (user) {
    return true;
  }

  return !!(await refreshToken());
};

Hub.listen('auth', ({ payload: { event, data } }) => {
  switch (event) {
    case 'signIn_failure':
    case 'cognitoHostedUI_failure': {
      localStorage.setItem(
        `${clientId}_url`,
        `${window.location.origin}/logout/unauthorized`
      );
      break;
    }

    case 'signIn': {
      if (data.signInUserSession.accessToken) {
        const { jwtToken } = data.signInUserSession.accessToken;
        setToken(jwtToken);

        const attempt = localStorage.getItem(`${clientId}_attempt`);
        localStorage.setItem(
          `${clientId}_attempt`,
          (attempt ? Number(attempt) + 1 : 1).toString()
        );
      }

      if (data.signInUserSession.idToken) {
        const { payload } = data.signInUserSession.idToken;

        setUser({
          first_name: payload.given_name,
          last_name: payload.family_name,
          emp_no: payload.profile,
          e_mail_address: payload.name,
        });

        window.location.href =
          localStorage.getItem(`${clientId}_url`) || window.location.href;
      }

      break;
    }

    case 'tokenRefresh': {
      localStorage.setItem(`${clientId}_url`, window.location.href);

      break;
    }
  }
});
