import React, { useCallback } from 'react';
import classNames from 'classnames';
import { ATMCheckbox } from 'shared-it-appmod-ui';
import Moment, {
  dateInEventRange,
  hasEvent,
  hasEventRange,
  IMoment,
} from 'src/libraries/moment.library';
import {
  eventLevels,
  eventSegments,
  ICalendarEvent,
  sortEvents,
} from 'src/libraries/calendar.library';
import CalendarEvent from '../calendar-event/calendar-event.component';
import styles from './calendar-week.module.scss';

type ICalendarWeek = {
  setNo: number;
  dayRef: React.RefObject<HTMLDivElement>;
  date: IMoment;
  minDate?: IMoment;
  events: ICalendarEvent[];
  week: IMoment[];
  active?: IMoment;
  selectedDay?: IMoment;
  selectedWeek: IMoment[];
  setActive: React.Dispatch<React.SetStateAction<IMoment | undefined>>;
  handleSelectedDay: (day: IMoment, isChecked: boolean) => void;
  handleCopyWeek: (week: IMoment[], isChecked: boolean) => void;
};

export const getEventLevel = (events: ICalendarEvent[], week: IMoment[]) => {
  const weekEvents = [...events].filter((value) =>
    dateInEventRange({
      event: {
        start: Moment(value.start),
        end: Moment(value.end),
      },
      range: {
        start: week[0],
        end: week[week.length - 1],
      },
    })
  );

  // const list = useMemo(
  //   () => weekEvents.sort((a, b) => sortEvents(a, b)),
  //   [weekEvents]
  // );
  const list = weekEvents.sort((a, b) => sortEvents(a, b));
  const segments = list.map((evt) => eventSegments(evt, week));
  const maxRows = 5;

  return eventLevels([...segments], Math.max(maxRows, 1));
};

const CalendarWeek: React.FC<ICalendarWeek> = ({
  setNo,
  dayRef,
  date,
  minDate,
  week,
  events,
  active,
  selectedDay,
  selectedWeek,
  handleSelectedDay,
  handleCopyWeek,
  setActive,
}) => {
  const { levels, extra } = getEventLevel(events, week);

  const handleClick = useCallback(
    (v: IMoment) => {
      if (
        (!minDate || (minDate && !v.isBefore(minDate))) &&
        !selectedDay &&
        v.isSame(date, 'month')
      ) {
        setActive((value) =>
          !value || (value && !value.isSame(v, 'day')) ? v : undefined
        );
      }
    },
    [minDate, selectedDay, setActive]
  );

  return (
    <div className={styles.monthRow}>
      <div className={styles.rowBg}>
        <div className={classNames(styles.dayBg, styles.checkbox)} />
        {week.map((v) => (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            ref={
              active && v.isSame(date, 'month') && v.isSame(active, 'day')
                ? dayRef
                : null
            }
            key={`bg_${setNo}_${v.format('YYYYMMDD')}`}
            className={classNames(styles.dayBg, {
              [styles.dayCurrentBg]: v.isSame(Moment(), 'day'),
              [styles.daySelected]:
                active && v.isSame(date, 'month') && v.isSame(active, 'day'),
              [styles.dayCopy]: selectedDay && selectedDay.isSame(v, 'day'),
              [styles.dayDisabled]: minDate && v.isBefore(minDate),
              [styles.dayOff]: !v.isSame(date, 'month'),
            })}
            onClick={() => handleClick(v)}
          />
        ))}
      </div>

      <div className={styles.rowContent}>
        <div className={styles.rowEvent}>
          <div className={classNames(styles.day, styles.weekCheckbox)}>
            <ATMCheckbox
              key={`${
                selectedWeek.length > 0 ? selectedWeek[0].format('WW') : ''
              }_${week[0].format('WW')}`}
              defaultChecked={
                selectedWeek.length > 0 &&
                week[0].isSame(selectedWeek[0], 'day')
              }
              onChange={(_, { checked }) => {
                handleCopyWeek(week, checked || false);
              }}
              disabled={
                (!!hasEventRange(selectedDay, events) &&
                  !week.some((v) => hasEvent(v, events))) ||
                week.every((v) => minDate && v.isBefore(minDate)) ||
                week.some((v) => hasEventRange(v, events))
              }
            />
          </div>
          {week.map((v) => (
            <div
              key={`day_${setNo}_${v.format('YYYYMMDD')}`}
              className={classNames(styles.day, {
                [styles.dayOff]: !v.isSame(date, 'month'),
                [styles.daySelected]: active && v.isSame(active, 'day'),
                [styles.hasCheckbox]: v.isSame(date, 'month'),
                [styles.hasCheckboxActive]: !!selectedDay,
                [styles.dayDisabled]: minDate && v.isBefore(minDate),
              })}
            >
              {minDate && v.isBefore(minDate) ? (
                <span>{v.format('D')}</span>
              ) : (
                <>
                  <span onClick={() => handleClick(v)} role="button">
                    {v.format('D')}
                  </span>
                  {((hasEvent(v, events) && !selectedDay) || selectedDay) &&
                    v.isSame(date, 'month') && (
                      <ATMCheckbox
                        key={`${
                          selectedDay && !v.isSame(selectedDay, 'day')
                            ? selectedDay.format('YYYYMMDD')
                            : '0'
                        }_${v.format('DD')}`}
                        defaultChecked={
                          selectedDay && v.isSame(selectedDay, 'day')
                        }
                        onChange={(_, { checked }) => {
                          handleSelectedDay(v, checked || false);
                        }}
                      />
                    )}
                </>
              )}
            </div>
          ))}
        </div>

        {levels.map((value, i) => {
          return (
            <div
              key={`event_${week[0].isoWeek()}_${i}`}
              className={styles.eventWrapper}
            >
              <CalendarEvent
                events={value.filter(
                  (v) =>
                    !v.event.start.isSame(v.event.end, 'month') ||
                    v.event.start.isSame(date, 'month')
                )}
                dayRange={week}
                handleClick={handleClick}
              />
            </div>
          );
        })}

        {!!extra.length && (
          <div className={styles.eventWrapper}>
            <CalendarEvent
              events={extra}
              dayRange={week}
              content={
                <div className={styles.showMore}>+{extra.length} more</div>
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(CalendarWeek);
