import React, { useCallback, useRef, useState } from 'react';
import { ATMGrid, ATMIcon } from 'shared-it-appmod-ui';
import ConfirmationContainer from 'src/components/atoms/confirmation/confirmation.component';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { useKeyRequestContext } from 'src/contexts/key-request.context';
import { useEmployeeContext } from 'src/contexts/employee.context';
import { keyRequestActionTypes } from 'src/ducks/key-request.duck';
import {
  IKeyRequest,
  IKeyRequestEmployeeIssueValidationForm,
  KeyRequestFormSchema,
  IKeyRequestForm,
} from 'src/models/key-request.model';
import { getKeyRequestStatus } from 'src/selectors/key-request.selector';
import { IEmployee } from 'src/models/employee.model';
import { convertKeyNumberToFiveDigits } from 'src/helpers/key-request.helper';
import KeyRequestEmployeeView from './key-request-employee.view';
import styles from '../key-request.module.scss';

type IProp = {
  data?: Partial<IKeyRequest>;
  isRequestor: boolean;
  handleDropdown?: (data) => void;
};

const KeyRequestEmployee: React.FC<IProp> = ({ isRequestor, handleDropdown }) => {
  const { state, actions } = useKeyRequestContext();
  const { state: employeeState, actions: employeeActions } =
    useEmployeeContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [confirm, setConfirm] = useState<IKeyRequest>();
  const [employee, setEmployee] = useState<IEmployee>();
  const [isDirtyEmployee, setIsDirtyEmployee] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const status = getKeyRequestStatus(
    state,
    keyRequestActionTypes.KEY_REQUEST_DATA_EMPLOYEE_CREATE
  );

  const dataGetStatus = getKeyRequestStatus(
    state,
    keyRequestActionTypes.KEY_REQUEST_DATA_READ
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IKeyRequestEmployeeIssueValidationForm) => {
      const response = await actions.createEmployeePOST(
        KeyRequestFormSchema.cast(formData) as IKeyRequestForm
      );
      if (!response.error) {
        setIsDirtyEmployee(false);

        if (response.payload) {
          employeeActions.searchGET(
            formData.empId.slice(2, formData.empId.length)
          );
          const insertedData = await actions.dataGET(
            response.payload.keyRequest.requestNbr
          );
          if (response.payload.infoMessage) {
            ToastSuccess(response.payload.infoMessage);
          }
          setConfirm(insertedData.payload);
          setIsOpen(false);
          setIsConfirmOpen(true);
          setIsDirtyEmployee(false);
        }
      } else if (response.error.name === 'BadRequest') {
        setIsOpen(false);
      } else {
        ToastError(response.error.message);
      }
      if (handleDropdown) {
        handleDropdown(false);
      }
    },
    [actions, setIsOpen, setIsConfirmOpen, handleDropdown]
  );

  const handleSearch = useCallback(
    (id) => {
      const user = employeeState.list.find((value) => value.emp_id === id);
      setEmployee(user);
    },
    [employeeState, setEmployee]
  );

  const printContent = `<table style="font-family: Calibri; margin: 0 auto;">
  <thead style="font-size: 36px;">
    <tr style="height: 100px">
      <th colspan="4">Confirmation - ${`Key Request for ${
        confirm && confirm.emp.lastName
      }, ${confirm && confirm.emp.lastName}/${confirm && confirm.empId}`}</th>
    </tr>
  </thead>
  <tbody style="font-size: 24px;">
    <tr>
      <td colspan="4" style="color: green;">&#10004; The Key Request has been successfully generated:</td>
    </tr>
      <tr style="height: 40px;">
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr style="font-weight: 800;">
        <td style="width:33%">Key Holder</td>
        <td style="width:33%">Supervisor/Manager</td>
        <td style="width:33%">Key Key Number</td>
      </tr>
      <tr>
        <td style="width:33%">${confirm && confirm.emp.lastName}, ${
    confirm && confirm.emp.firstName
  }/${confirm && confirm.empId}</td>
        <td style="width:33%">${
          confirm && confirm.keyRequestDetail.supvr
            ? `${confirm.keyRequestDetail.supvr.lastName}, ${confirm.keyRequestDetail.supvr.firstName}/${confirm.keyRequestDetail.supvrNbr}`
            : '-'
        }</td>
        <td style="width:33%">${
          confirm &&
          confirm.keyRequestDetail.keyNbr &&
          convertKeyNumberToFiveDigits(confirm.keyRequestDetail.keyNbr)
        }</td>
      </tr>
      <tr style="height: 40px;">
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr style="font-weight: 800;">
        <td style="width:33%">Key Admin Location</td>
        <td style="width:33%">Key Type</td>
      </tr>
      <tr>
        <td style="width:33%">${
          confirm && employeeState.list?.[0]?.mailstop_id
        }</td>
        <td style="width:33%">${
          confirm && confirm.keyRequestDetail.keyTypId
        }</td>
      </tr>
      <tr style="height: 40px;">
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr style="font-weight: 800;">
        <td>Issuance Reason</td>
      </tr>
      <tr>
        <td colspan="4">
          -
        </td>
      </tr>
  </tbody>
</table>`;

  const modalContent = (
    <>
      <ATMGrid>
        <ATMGrid.Row>
          <ATMGrid.Column>
            <p>
              <ATMIcon color="green" name="check circle" />{' '}
              <span className={styles.successMsg}>
                The Key Request has been successfully generated:
              </span>
            </p>
          </ATMGrid.Column>
        </ATMGrid.Row>
        <ATMGrid.Row columns="equal">
          <ATMGrid.Column width={5}>
            {confirm && (
              <FieldReadOnly label="Key Holder">
                <p>
                  {confirm.emp.lastName}, {confirm.emp.firstName}/
                  {confirm.emp.empId}
                </p>
              </FieldReadOnly>
            )}
          </ATMGrid.Column>
          <ATMGrid.Column width={5}>
            {confirm && (
              <FieldReadOnly label="Supervisor/Manager">
                <p>
                  {confirm.keyRequestDetail.supvr
                    ? `${confirm.keyRequestDetail.supvr.lastName}, ${confirm.keyRequestDetail.supvr.firstName}/${confirm.keyRequestDetail.supvrNbr}`
                    : '-'}
                </p>
              </FieldReadOnly>
            )}
          </ATMGrid.Column>
          <ATMGrid.Column width={5}>
            <FieldReadOnly label="Key Number">
              <p>-</p>
            </FieldReadOnly>
          </ATMGrid.Column>
        </ATMGrid.Row>
        <ATMGrid.Row columns="equal">
          <ATMGrid.Column width={5}>
            {confirm && (
              <FieldReadOnly label="Key Admin Location">
                <p>{employeeState.list?.[0]?.mailstop_id}</p>
              </FieldReadOnly>
            )}
          </ATMGrid.Column>
          <ATMGrid.Column width={5}>
            {confirm && (
              <FieldReadOnly label="Key Type">
                <p>{confirm.keyRequestDetail.keyTypId}</p>
              </FieldReadOnly>
            )}
          </ATMGrid.Column>
        </ATMGrid.Row>
        <ATMGrid.Row columns="equal">
          <ATMGrid.Column>
            <FieldReadOnly label="Issuance Reason">
              <p>-</p>
            </FieldReadOnly>
          </ATMGrid.Column>
        </ATMGrid.Row>
      </ATMGrid>
    </>
  );

  return (
    <>
      {confirm && (
        <ConfirmationContainer
          content={modalContent}
          printContent={printContent}
          header={`Key Request for ${confirm.emp.lastName}, ${confirm.emp.firstName}/${confirm.emp.empId}`}
          isOpen={isConfirmOpen}
          loading={dataGetStatus.fetching}
          handleOpen={setIsConfirmOpen}
          url="/keys?stat=0"
        />
      )}
      <KeyRequestEmployeeView
        formRef={formRef}
        loading={status.fetching || dataGetStatus.fetching}
        handleClick={handleClick}
        handleSubmit={handleSubmit}
        isOpen={isOpen}
        handleOpen={setIsOpen}
        employee={employee}
        handleSearch={handleSearch}
        isDirtyEmployee={isDirtyEmployee}
        setIsDirtyEmployee={setIsDirtyEmployee}
        isRequestor={isRequestor}
        handleDropdown={handleDropdown}
      />
    </>
  );
};

export default KeyRequestEmployee;
