/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import {
  ATMButton,
  ATMCheckbox,
  ATMField,
  ATMGrid,
  ATMHeader,
  ATMInput,
  ATMLoader,
  ATMSegment,
  ATMTable,
} from 'shared-it-appmod-ui';
import Status from 'src/components/atoms/status/status.component';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import Lang from 'src/libraries/language';
import {
  getLerRequestEndDate,
  getLerRequestStartDate,
  getLerRequestStatus,
} from 'src/selectors/ler-request.selector';
import styles from './ler-request-related-ler.module.scss';

type IProps = {
  grpId: number | null;
  requestId: number;
};

const LerRequestRelatedLer: React.FC<IProps> = ({ grpId, requestId }) => {
  const { state, actions } = useLerRequestContext();
  const [newRelatedLER, setNewRelatedLER] = useState();
  const [isChecked, setIsChecked] = useState<Array<number>>();
  const [enable, setEnable] = useState(false);
  const [counter, setCounter] = useState(0);

  const loading = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_GRP_ID_LIST_READ
  );

  const addLoading = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_ADD_RELATED_LER_UPDATE
  );

  const removeLoading = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_REMOVE_RELATED_LER_UPDATE
  );

  const handleAddLER = useCallback(async () => {
    const result = await actions.addRelatedLerPUT(
      Number(newRelatedLER),
      requestId
    );
    if (!result.error) {
      ToastSuccess(
        Lang.formatString(
          Lang.MSG_ADD_RELATED_LER_SUCCESS,
          Number(newRelatedLER)
        )
      );
      setNewRelatedLER(undefined);
      actions.grpIdListGET({
        filters: [
          {
            name: 'grpId',
            value: result.payload?.grpId,
          },
        ],
      });
    } else {
      ToastError(result.error.message);
    }
    setCounter(counter + 1);
  }, [newRelatedLER, actions, requestId, setNewRelatedLER, setCounter]);

  const handleRemove = useCallback(async () => {
    if (isChecked && isChecked.length > 0) {
      const result = await actions.removeRelatedLerPUT(isChecked, requestId);

      if (!result.error) {
        ToastSuccess(
          Lang.formatString(
            Lang.MSG_REMOVE_RELATED_LER_SUCCESS,
            isChecked.toString()
          )
        );
        actions.grpIdListGET({
          filters: [
            {
              name: 'grpId',
              value: result.payload?.grpId,
            },
          ],
        });
        setIsChecked(undefined);
        setEnable(false);
      } else {
        ToastError(result.error.message);
      }
    }
  }, [isChecked]);

  const handleChecked = useCallback(
    (id: number) => {
      if (isChecked && isChecked.length > 0) {
        if (isChecked.includes(id)) {
          const pastChecked = isChecked;
          pastChecked.splice(isChecked.indexOf(id), 1);
          setIsChecked(pastChecked);
        } else {
          const newChecked = isChecked;
          newChecked.push(id);
          setIsChecked(newChecked);
        }
        setEnable(isChecked.length > 0);
      } else {
        setIsChecked([id]);
        setEnable(true);
      }
    },
    [isChecked]
  );

  useEffect(() => {
    actions.setGrpIdList();
    if (grpId) {
      actions.grpIdListGET({
        filters: [
          {
            name: 'grpId',
            value: grpId,
          },
        ],
      });
    }
  }, [grpId, actions]);

  if (loading.fetching) {
    return (
      <div style={{ marginTop: '1rem' }}>
        <ATMHeader
          className={styles.subHeader}
          as="h2"
          content={Lang.TTL_RELATED_LERS}
        />
        <ATMLoader active inline="centered" />
      </div>
    );
  }
  return (
    <ATMSegment className={styles.content}>
      <ATMHeader
        className={styles.subHeader}
        as="h2"
        content={Lang.TTL_RELATED_LERS}
      />
      {state.grpIdList && state.grpIdList.length > 0 ? (
        <>
          <ATMGrid columns="equal" className={styles.fieldLer}>
            <ATMGrid.Row>
              <ATMGrid.Column width={4}>
                <ATMField
                  name="newLer"
                  key={`${counter}`}
                  as={ATMInput}
                  onChange={
                    ((_, { value }) => {
                      setNewRelatedLER(value);
                      return value;
                    }) as any
                  }
                  type="number"
                />
              </ATMGrid.Column>
              <ATMGrid.Column>
                <ATMButton
                  type="button"
                  size="tiny"
                  primary
                  content={`${Lang.LBL_ADD} LER`}
                  onClick={handleAddLER}
                  loading={addLoading.fetching}
                  disabled={addLoading.fetching || !newRelatedLER}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
          </ATMGrid>
          <ATMTable>
            <ATMTable.Header>
              <ATMTable.Row>
                <ATMTable.HeaderCell> </ATMTable.HeaderCell>
                <ATMTable.HeaderCell>
                  {Lang.LBL_RELATED_LER_NO}
                </ATMTable.HeaderCell>
                <ATMTable.HeaderCell>{Lang.LBL_KV}</ATMTable.HeaderCell>
                <ATMTable.HeaderCell>{Lang.LBL_FACILITY}</ATMTable.HeaderCell>
                <ATMTable.HeaderCell>{Lang.LBL_AUTH}</ATMTable.HeaderCell>
                <ATMTable.HeaderCell>{Lang.LBL_DATES}</ATMTable.HeaderCell>
                <ATMTable.HeaderCell>{Lang.LBL_STATUS}</ATMTable.HeaderCell>
              </ATMTable.Row>
            </ATMTable.Header>
            {state.grpIdList.map((val, index) => (
              <ATMTable.Row key={index}>
                <ATMTable.Cell>
                  <ATMCheckbox
                    name={`${val.requestId}-${counter}`}
                    key={`${val.requestId}-${counter}`}
                    onChange={() => handleChecked(Number(val.requestId))}
                  />
                </ATMTable.Cell>
                <ATMTable.Cell>
                  <a
                    onClick={() => {
                      window.open(`/outage-planning?lerView=${val.requestId}`, '_blank');
                  }}
                    role="button"
                  >
                {val.requestId}
                </a>
                </ATMTable.Cell>
                <ATMTable.Cell>
                  {val.outageFacility.facility?.volt?.voltNm}
                </ATMTable.Cell>
                <ATMTable.Cell>
                  {val.outageFacility.facility?.outgFacNm}
                </ATMTable.Cell>
                <ATMTable.Cell>
                  {val.outageFacility.outageType?.outageTypeDesc}
                </ATMTable.Cell>
                <ATMTable.Cell>{`${getLerRequestStartDate(
                  val.outageDates
                )} - ${getLerRequestEndDate(val.outageDates)}`}</ATMTable.Cell>
                <ATMTable.Cell>
                  <Status
                    language={Lang.LER_REQUEST_STATUS}
                    value={val.requestStat}
                    label
                  />
                </ATMTable.Cell>
              </ATMTable.Row>
            ))}
          </ATMTable>
          <ATMButton
            style={{ marginTop: '0.5em' }}
            type="button"
            size="tiny"
            color="red"
            content={`${Lang.LBL_REMOVE} LER(s)`}
            disabled={!enable || removeLoading.fetching}
            onClick={handleRemove}
            loading={removeLoading.fetching}
          />
        </>
      ) : (
        <ATMGrid columns="equal">
          <ATMGrid.Row columns="2">
            <ATMGrid.Column width={7}>
              <ATMField
                key={`${counter}`}
                name="newLer"
                as={ATMInput}
                onChange={
                  ((_, { value }) => {
                    setNewRelatedLER(value);
                    return value;
                  }) as any
                }
                type="number"
              />
            </ATMGrid.Column>
            <ATMGrid.Column>
              <ATMButton
                type="button"
                size="tiny"
                primary
                content={`${Lang.LBL_ADD} LER`}
                onClick={handleAddLER}
                loading={addLoading.fetching}
                disabled={addLoading.fetching || !newRelatedLER}
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>
      )}
    </ATMSegment>
  );
};

export default LerRequestRelatedLer;
