/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from 'react';
import {
  ATMForm,
  ATMGrid,
  ATMField,
  ATMInput,
  ATMButton,
  ATMMessage,
} from 'shared-it-appmod-ui';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import LabelRequired from 'src/components/atoms/label-required/label-required.component';
import { useEmployeeContext } from 'src/contexts/employee.context';
import { useKeyRequestContext } from 'src/contexts/key-request.context';
import { employeeActionTypes } from 'src/ducks/employee.duck';
import { keyRequestActionTypes } from 'src/ducks/key-request.duck';
import { formatToRawEmployeeId } from 'src/helpers/employee.helper';
import { checkUndefinedOrNullValue } from 'src/helpers/key-request.helper';
import Lang from 'src/libraries/language';
import {
  IKeyRequest,
  IKeyRequestForm,
  // KeyRequestEmployeeIssueValidationFormSchema,
  KeyRequestFormSchema,
  // KeyRequestFormSchema,
} from 'src/models/key-request.model';
import { getEmployeeStatus } from 'src/selectors/employee.selector';
import { getKeyRequestStatus } from 'src/selectors/key-request.selector';
import style from './key-request-form.module.scss';

type IProp = {
  formRef?: React.RefObject<HTMLFormElement>;
  defaultValues?: IKeyRequest;
  handleSubmit: (data: IKeyRequestForm) => void;
  handleEnable: (value: boolean) => void;
};

const KeyRequestForm: React.FC<IProp> = ({
  formRef,
  defaultValues,
  handleSubmit,
  handleEnable,
}) => {
  // const [key, setKey] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [validationMessage, setValidationMessage] = useState({
    valid: false,
    message: '',
  });
  const { state, actions } = useKeyRequestContext();
  const { state: employeeState } = useEmployeeContext();
  const employeeStatus = getEmployeeStatus(
    employeeState,
    employeeActionTypes.EMPLOYEE_SEARCH_FETCH
  );
  const status = getKeyRequestStatus(
    state,
    keyRequestActionTypes.KEY_REQUEST_VALIDATE_READ
  );

  const handleKeyStatus = useCallback(
    async (control, getValues) => {
      const result = await actions?.validateGET(
        getValues('keyRequestDetail.keyNbr'),
        1
      );

      if (!result.error) {
        handleEnable(true);
      }

      setShowMessage(true);
      setValidationMessage({
        valid: !result.error,
        message: result.payload
          ? result.payload?.message
          : result.error.message,
      });
    },
    [actions, showMessage, validationMessage]
  );

  return (
    <ATMForm
      ref={formRef}
      onSubmit={handleSubmit}
      mode="onChange"
      defaultValues={
        KeyRequestFormSchema.cast({
          ...defaultValues,
          keyRequestDetail: {
            ...defaultValues?.keyRequestDetail,
            keyDetail: {
              firmName: null,
              reason: defaultValues?.requestTyp ?? '',
            },
          },
        }) as any
      }
      validationSchema={KeyRequestFormSchema}
    >
      {({ control, formState: { errors, isDirty }, getValues }) => {
        handleEnable(isDirty);
        return (
          <>
            {/* Hidden Form */}
            <ATMField
              name="requestNbr"
              control={control}
              defaultValue={defaultValues?.requestNbr}
              as={ATMInput}
              type="hidden"
              className="hidden"
            />
            <ATMField
              name="empId"
              control={control}
              defaultValue={defaultValues?.empId}
              as={ATMInput}
              type="hidden"
              className="hidden"
            />
            <ATMField
              name="keyRequestDetail.keyTypId"
              control={control}
              defaultValue={
                defaultValues && defaultValues.keyRequestDetail.keyTypId
              }
              as={ATMInput}
              type="hidden"
              className="hidden"
            />
            <ATMField
              name="requestTyp"
              control={control}
              as={ATMInput}
              type="hidden"
              className="hidden"
            />
            <ATMField
              name="requestorTyp"
              control={control}
              as={ATMInput}
              type="hidden"
              className="hidden"
            />
            <ATMField
              name="requestedBy"
              control={control}
              as={ATMInput}
              type="hidden"
              className="hidden"
            />
            <ATMField
              name="keyRequestDetail.noOfKeys"
              control={control}
              as={ATMInput}
              type="hidden"
              className="hidden"
            />
            <ATMField
              name="emp.jobCd"
              control={control}
              as={ATMInput}
              type="hidden"
              className="hidden"
            />
            <ATMField
              name="keyRequestDetail.approvalEmail"
              control={control}
              as={ATMInput}
              type="hidden"
              className="hidden"
            />
            <ATMField
              name="keyRequestDetail.keyAdminMailLoc"
              control={control}
              as={ATMInput}
              type="hidden"
              className="hidden"
            />
            <ATMField
              name="keyRequestDetail.keyDetail.reason"
              control={control}
              as={ATMInput}
              type="hidden"
              className="hidden"
            />
            <ATMField
              name="keyRequestDetail.keyDetail.firmName"
              control={control}
              as={ATMInput}
              type="hidden"
              className="hidden"
            />
            <ATMGrid>
              <ATMGrid.Row columns="equal" style={{ paddingBottom: '0px' }}>
                <ATMGrid.Column>
                  <FieldReadOnly label={Lang.LBL_KEY_HOLDER}>
                    {defaultValues?.emp
                      ? `${checkUndefinedOrNullValue(
                          `${defaultValues?.emp.lastName}, ${defaultValues?.emp.firstName}`,
                          Lang.LBL_UNAVAILABLE
                        )}${
                          defaultValues?.empId.length
                            ? `/${formatToRawEmployeeId(defaultValues?.empId)}`
                            : ''
                        }`
                      : '-'}
                  </FieldReadOnly>
                </ATMGrid.Column>
                <ATMGrid.Column>
                  <FieldReadOnly label={Lang.LBL_SUPERVISOR_MANAGER}>
                    {defaultValues?.keyRequestDetail.supvr
                      ? `${checkUndefinedOrNullValue(
                        `${defaultValues?.keyRequestDetail.supvr.lastName}, ${defaultValues?.keyRequestDetail.supvr.firstName}`,
                        Lang.LBL_UNAVAILABLE
                      )}${defaultValues?.keyRequestDetail.supvrNbr?.length
                        ? checkUndefinedOrNullValue(
                          `/${formatToRawEmployeeId(
                            defaultValues?.keyRequestDetail.supvrNbr
                          )}`,
                          ''
                        )
                        : ''
                      }`
                      : !employeeStatus.fetching && `${employeeState.list?.[0]?.mgr_last_name}, ${employeeState.list?.[0]?.mgr_first_name} / ${employeeState.list?.[0]?.mgr_empno}`
                    }
                  </FieldReadOnly>
                </ATMGrid.Column>
              </ATMGrid.Row>
              <ATMGrid.Row columns="equal" style={{ paddingBottom: '0px' }}>
                <ATMGrid.Column>
                  <FieldReadOnly label={Lang.LBL_KEY_ADMIN_lOCATION}>
                    {employeeStatus.fetching
                      ? defaultValues?.keyRequestDetail.keyAdminMailLoc
                      : employeeState.list?.[0]?.mailstop_id}
                  </FieldReadOnly>
                </ATMGrid.Column>
                <ATMGrid.Column>
                  <FieldReadOnly label={Lang.LBL_KEY_TYPE}>
                    {defaultValues?.keyRequestDetail.keyTypId}
                  </FieldReadOnly>
                </ATMGrid.Column>
              </ATMGrid.Row>
              <ATMGrid.Row style={{ paddingBottom: '0px' }}>
                <ATMGrid.Column>
                  <FieldReadOnly label={Lang.LBL_ISSUANCE_REASON}>
                    {defaultValues?.keyRequestDetail.keyDetail.length &&
                    defaultValues?.keyRequestDetail.keyDetail[0].reason
                      ? defaultValues?.keyRequestDetail.keyDetail[0].reason
                      : '-'}
                  </FieldReadOnly>
                </ATMGrid.Column>
              </ATMGrid.Row>
              <ATMGrid.Row columns="equal" style={{ paddingBottom: '0px' }}>
                <ATMGrid.Column columns="2">
                  <ATMField
                    as={ATMInput}
                    name="keyRequestDetail.keyNbr"
                    label={
                      <LabelRequired>
                        {Lang.LBL_KEY_REQUEST_ASSIGN_KEY_NUMBER}
                      </LabelRequired>
                    }
                    type="number"
                    control={control}
                    error={errors.keyRequestDetail?.keyNbr}
                  />
                </ATMGrid.Column>
                <ATMGrid.Column>
                  <ATMButton
                    type="button"
                    secondary
                    name="validateKey"
                    content={Lang.LBL_VALIDATE_AVAILABILITY}
                    className={style.validateBtn}
                    onClick={() => handleKeyStatus(control, getValues)}
                    loading={status.fetching}
                    id="validate"
                    disabled={
                      !!errors.keyRequestDetail?.keyNbr ||
                      !getValues().keyRequestDetail?.keyNbr
                    }
                  />
                </ATMGrid.Column>
              </ATMGrid.Row>
              <ATMGrid.Row>
                <ATMGrid.Column width="16">
                  {showMessage ? (
                    validationMessage.valid ? (
                      <ATMMessage positive>
                        <h4>{Lang.MSG_VALID_KEY_NUMBERS}</h4>
                        <p>{validationMessage.message}</p>
                        {validationMessage.valid}
                      </ATMMessage>
                    ) : (
                      <ATMMessage negative>
                        <h4>{Lang.MSG_INVALID_KEY_NUMBERS}</h4>
                        <p>{validationMessage.message}</p>
                        {validationMessage.valid}
                      </ATMMessage>
                    )
                  ) : null}
                </ATMGrid.Column>
              </ATMGrid.Row>
            </ATMGrid>
          </>
        );
      }}
    </ATMForm>
  );
};

export default KeyRequestForm;
