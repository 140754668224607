import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
// import { Asserts } from 'yup';
import { httpClient } from 'src/libraries/http.library';
import {
  IVoltForm,
  VoltListPayloadSchema,
  VoltDeletePayloadSchema,
  VoltPayloadSchema,
} from 'src/models/volt.model';
// import { voltMock } from 'src/models/mocks/volt.mock';

const client = httpClient();
const endpoint = 'volt';

const services = {
  dataGET: async (id: number) => {
    return client.get(`/${endpoint}/${id}`, {}, VoltPayloadSchema);
  },

  listGET: async (params?: Partial<IORGDataTableQueryState>) => {
    // if (process.env.NODE_ENV !== 'test') {
    //   return new Promise<Asserts<typeof VoltListPayloadSchema>>((resolve) => {
    //     const rows = Array(10)
    //       .fill(null)
    //       .map((_, i) =>
    //         voltMock({
    //           voltId: i + 1,
    //         })
    //       );

    //     setTimeout(() => {
    //       resolve({
    //         count: rows.length,
    //         rows,
    //       });
    //     }, 1000);
    //   });
    // }
    return client.get(`/${endpoint}`, params, VoltListPayloadSchema);
  },

  createPOST: async (data: IVoltForm) => {
    return client.post(`/${endpoint}`, data, VoltPayloadSchema);
  },

  updatePUT: async (id: number, data: IVoltForm) => {
    return client.put(`/${endpoint}/${id}`, data, VoltPayloadSchema);
  },

  dataDELETE: async (id: number) => {
    return client.delete(`/${endpoint}/${id}`, VoltDeletePayloadSchema);
  },
};

export default services;
