/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  ORGDataTable,
  ATMDatePicker,
  ATMButton,
  IORGDataTableQueryState,
  IORGDataTableColumn,
  MOLInfoText,
  ATMPopover,
} from 'shared-it-appmod-ui';
import ListStatus from 'src/components/atoms/list-status/list-status.component';
import { useTroubleJobsContext } from 'src/contexts/trouble-jobs.context';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import Lang from 'src/libraries/language';
import Moment, { formatDate } from 'src/libraries/moment.library';
import TableSearch from 'src/components/atoms/table/table-search/table-search.component';
import { ITroubleJobs } from 'src/models/trouble-jobs.model';
import { getTroubleJobsStatus } from 'src/selectors/trouble-jobs.selector';
import { troubleJobsActionTypes } from 'src/ducks/trouble-jobs.duck';
import TroubleJobsEdit from '../trouble-jobs-edit/trouble-jobs-edit.component';
import styles from './trouble-jobs.module.scss';

type IProps = {
  data: ITroubleJobs[];
  loading: boolean;
  handleFetch: (params: IORGDataTableQueryState) => void;
  total: number;
  openTotal: number;
  currentTab: number;
  handleDownload: (params: IORGDataTableQueryState) => void;
};

const TroubleJobListTables: React.FC<IProps> = ({
  data,
  loading,
  handleFetch,
  handleDownload,
}) => {
  const { state: troubleJobState } = useTroubleJobsContext();
  const { handleChange } = useLocationParams(['id']);
  const status = getTroubleJobsStatus(
    troubleJobState,
    troubleJobsActionTypes.TROUBLE_JOBS_DATA_READ
  );
  const columns: IORGDataTableColumn<ITroubleJobs>[] = [
    {
      index: 'troubleJobId',
      title: 'Trouble Job',
      render: (_, value) => (
        <>
          <u>
            <a
              role="button"
              onClick={() =>
                handleChange({
                  id: value.troubleJobId,
                })
              }
            >
              <b>{value.troubleJobId}</b>
            </a>
          </u>
          <MOLInfoText transparent limit={150} content={value.jobDescription} />
        </>
      ),
    },
    {
      index: 'jobStatus',
      title: 'Status',
      render: (_, value) => {
        return <ListStatus value={value.jobStatus} label />;
      },
    },
    {
      index: 'beginDate',
      title: 'Begin Date',
      render: (_, value) => formatDate(value.beginDate),
    },
    {
      index: 'endDate',
      title: 'End Date',
      render: (_, value) => formatDate(value.endDate),
    },
    {
      index: 'facility.outgFacNm',
      title: 'Facility Name',
    },
    {
      index: 'substation.name',
      title: 'Substation',
    },
    {
      index: 'primary.isoOutgTypeDesc',
      title: 'Primary Cause',
    },
    {
      index: 'secondary.isoOutgTypeDesc',
      title: 'Secondary Cause',
    },
    {
      index: 'submittedBy',
      title: 'Created By',
    },
  ];

  const table = (
    <div className="admin-content">
      <TroubleJobsEdit dataStatus={status.fetching} />
      <ORGDataTable
        celled={false}
        columns={columns}
        data={data}
        onChange={handleFetch}
        loading={loading}
        showPagination
        rowsPerPageOptions={[50, 100, 150]}
        defaultRowsPerPage={50}
        sortable
        // total={openTotal}
        addParams={[
          'jobDescription',
          'troubleJobId',
          'jobStatus',
          'facilityType',
          'facilityName',
        ]}
        counter
      >
        {({ state, setState }) => ({
          toolbars: {
            jobDescription: () => (
              <span className={styles.selectables}>
                <TableSearch
                  field="jobDescription"
                  state={state}
                  setState={setState}
                  placeholder={Lang.LBL_DESCRIPTION}
                />
              </span>
            ),
            jobNumberId: () => (
              <span className={styles.selectables}>
                <TableSearch
                  field="troubleJobId"
                  state={state}
                  setState={setState}
                  placeholder={Lang.LBL_JOB_NUMBER}
                />
              </span>
            ),
            outgFacNm: () => (
              <TableSearch
                field="outgFacNm"
                state={state}
                setState={setState}
                placeholder={Lang.LBL_FACILITY_NAME}
              />
            ),
            dateRange: () => (
              <span className={styles.dateRange}>
                <ATMDatePicker
                  type="range"
                  size="small"
                  format="MM/DD/YYYY"
                  pointing="right"
                  placeholder="Date Range"
                  onChange={(_, { value }) => {
                    if (value === null) {
                      setState({
                        ...state,
                        page: 1,
                        filters: [
                          ...(state.filters?.filter(
                            (el) => el.name !== 'beginDate'
                          ) ?? []),
                        ],
                      });
                    } else if (value && (value as Date[]).length === 2) {
                      const [start, end] = value as Date[];
                      const rangeValue = [
                        Moment(start).startOf('day').toDate(),
                        Moment(end).endOf('day').toDate(),
                      ];

                      setState({
                        ...state,
                        page: 1,
                        filters: [
                          ...(state.filters?.filter(
                            (el) => el.name !== 'beginDate'
                          ) ?? []),
                          {
                            name: 'beginDate',
                            value: rangeValue,
                          },
                        ],
                      });
                    }
                  }}
                />
              </span>
            ),

            buttons: () => (
              <span>
                <ATMPopover
                  position="bottom left"
                  content="Download"
                  on="hover"
                  trigger={
                    <ATMButton
                      key="download"
                      secondary
                      size="tiny"
                      icon="download"
                      onClick={() => {
                        handleDownload({
                          ...state,
                          page: 1,
                          limit: 0,
                        });
                      }}
                    />
                  }
                />
              </span>
            ),
          },
        })}
      </ORGDataTable>
    </div>
  );

  return table;
};

export default TroubleJobListTables;
