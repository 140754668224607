import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { LERRequestAction, SwitchingSource } from 'src/constants';
import { httpClient } from 'src/libraries/http.library';
import {
  ILerRequestForm,
  LerRequestListPayloadSchema,
  LerRequestDeletePayloadSchema,
  LerRequestPayloadSchema,
  LerRequestDraftSchema,
  LerRequestDraftListPayloadSchema,
  LerRequestItemListPayloadSchema,
  LerRequestCountSchema,
  ILerRequestCancelForm,
  SendCaisoSchema,
  ILerRequestRejectSchema,
  ILerRequestEmailNotification,
  EmailGroupMultiplePayloadSchema,
  EmailGroupSchema,
  ILerRequestSingleEmail,
  ILerRequestApproveRejectEmail,
  LerRequestVersionListSchema,
  TransmissionOutageListPayloadSchema,
  ISystemChangeTasksEmail,
  LerRequestEmployeeListPayloadSchema,
  QueryMessageCountSchema,
  LerRequestItemSearchListPayloadSchema,
  ILerRequest,
  LerRequestRelatedLerListPayloadSchema,
  ILerRequestEtsFormDispatch,
} from 'src/models/ler-request.model';
import { SwitchingCreatePayloadSchema } from 'src/models/switching.model';

const client = httpClient();
const endpoint = 'outage-request';
const endpointCAISO = 'transmission-outage';
const endpointEmail = 'email-group';
const switching = 'switching-request';
const outageCalendar = 'outage-date-calendar';

const services = {
  dataGET: async (
    requestId: number,
    version?: number,
    isForSwitchingStat?: boolean
  ) => {
    return client.get(
      `/${endpoint}/${requestId}`,
      { version, isForSwitchingStat },
      LerRequestPayloadSchema
    );
  },

  crListGET: async (data: Partial<IORGDataTableQueryState> = {}) => {
    return client.get(
      `/${endpoint}`,
      {
        ...data,
        action: 'Change Requested',
      },
      LerRequestListPayloadSchema
    );
  },

  listGET: async (data: Partial<IORGDataTableQueryState> = {}) => {
    return client.get(`/${endpoint}`, data, LerRequestListPayloadSchema);
  },

  employeeListGET: async () => {
    return client.get(
      `/${endpoint}`,
      {
        action: 'employees',
      },
      LerRequestEmployeeListPayloadSchema
    );
  },

  searchGET: async (keyword: string) => {
    return client.get(
      `/${endpoint}`,
      {
        action: 'search',
        keyword,
      },
      LerRequestItemListPayloadSchema
    );
  },

  relatedOutagesListGET: async (grpIds: Array<number>) => {
    return client.get(
      `/${endpoint}`,
      {
        action: 'related-outages',
        relatedOutages: {
          grpIds,
        },
      },
      LerRequestRelatedLerListPayloadSchema
    );
  },

  listCalendarGET: async (data: Partial<IORGDataTableQueryState> = {}) => {
    return client.get(`/${outageCalendar}`, data, LerRequestListPayloadSchema);
  },

  listItemGET: async (data: Partial<IORGDataTableQueryState> = {}) => {
    const params: Record<string, any> = {
      ...data,
    };

    // US #508370 Sorting
    if (params.order) {
      params.order = params.order === 'ascending' ? 'asc' : 'desc';
    }

    return client.get(`/${endpoint}`, params, LerRequestItemListPayloadSchema);
  },

  listItemSearchGET: async (data: Partial<IORGDataTableQueryState> = {}) => {
    return client.get(
      `/${endpoint}`,
      data,
      LerRequestItemSearchListPayloadSchema
    );
  },

  draftListGET: async (data?: Partial<IORGDataTableQueryState>) => {
    return client.get(
      `/${endpoint}`,
      {
        ...data,
        action: 'draft',
      },
      LerRequestDraftListPayloadSchema
    );
  },

  createPOST: async (data: ILerRequestForm) => {
    return client.post(`/${endpoint}`, data, LerRequestPayloadSchema);
  },

  draftPOST: async (data: Partial<ILerRequestForm>) => {
    return client.post(
      `/${endpoint}?action=draft`,
      data,
      LerRequestDraftSchema
    );
  },

  draftPUT: async (id: number, data: Partial<ILerRequestForm>) => {
    return client.put(
      `/${endpoint}/${id}?action=draft`,
      data,
      LerRequestDraftSchema
    );
  },

  updatePUT: async (id: number, data: ILerRequestForm) => {
    return client.put(`/${endpoint}/${id}`, data, LerRequestPayloadSchema);
  },

  switchingUpdatePUT: async (id: number, data: ILerRequestForm) => {
    return client.put(
      `/${endpoint}/${id}?action=switching`,
      data,
      LerRequestPayloadSchema
    );
  },

  dataDELETE: async (id: number) => {
    return client.delete(`/${endpoint}/${id}`, LerRequestDeletePayloadSchema);
  },

  changeDELETE: async (id: number) => {
    return client.delete(
      `/${endpoint}/${id}?action=change-request`,
      LerRequestDeletePayloadSchema
    );
  },

  draftDELETE: async (id: number) => {
    return client.delete(
      `/${endpoint}/${id}?action=draft`,
      LerRequestDeletePayloadSchema
    );
  },

  cancelPUT: async (id: number, data: ILerRequestCancelForm) => {
    return client.put(
      `/${endpoint}/${id}?action=cancel`,
      data,
      LerRequestPayloadSchema
    );
  },

  changeRequestGET: async (id: number) => {
    return client.get(
      `/${endpoint}/${id}`,
      {
        action: 'change-request',
      },
      LerRequestPayloadSchema
    );
  },

  listChangeRequestGET: async (id: number) => {
    return client.get(
      `/${endpoint}/${id}`,
      {
        action: 'change-request-list',
      },
      LerRequestItemListPayloadSchema
    );
  },

  statusPUT: async (requestId: number, action: LERRequestAction) => {
    return client.put(
      `/${endpoint}/${requestId}?action=${action}`,
      {},
      LerRequestPayloadSchema
    );
  },

  crStatusPUT: async (
    requestId: number,
    status:
      | LERRequestAction.ApproveChangeRequest
      | LERRequestAction.RejectChangeRequest
      | LERRequestAction.CancelChangeRequest,
    note?: string
  ) => {
    return client.put(
      `/${endpoint}/${requestId}?action=change-request`,
      {
        status,
        note,
      },
      LerRequestPayloadSchema
    );
  },

  rejectPUT: async (requestId: number, data: ILerRequestRejectSchema) => {
    return client.put(
      `/${endpoint}/${requestId}?action=reject`,
      {
        ...data,
      },
      LerRequestPayloadSchema
    );
  },

  assignPUT: async (requestId, empId) => {
    return client.put(
      `/${endpoint}/${requestId}?action=assign`,
      {
        empId,
      },
      LerRequestPayloadSchema
    );
  },

  versionGET: async (id: number) => {
    return client.get(
      `/${endpoint}?action=history`,
      { 'filters[]': { name: 'requestIds', value: [id] } },
      LerRequestItemListPayloadSchema
    );
  },

  versionSelectedGET: async (
    id: number,
    versions: Array<string> | undefined
  ) => {
    return client.get(
      `/${endpoint}/${id}?action=history-by-versions`,
      { 'filters[]': { name: 'versions', value: versions ?? [] } },
      LerRequestVersionListSchema
    );
  },

  sendCaisoPOST: async (data: ILerRequest) => {
    // const data = { requestId };
    return client.post(`/${endpointCAISO}`, data, SendCaisoSchema);
  },

  dataCaisoGET: async (requestId: number) => {
    return client.get(
      `/${endpointCAISO}`,
      { 'filters[]': { name: 'lerId', value: requestId } },
      TransmissionOutageListPayloadSchema
    );
  },

  countGET: async (
    list: IORGDataTableQueryState['filters'],
    change: IORGDataTableQueryState['filters']
  ) => {
    return client.get(
      `/${endpoint}`,
      {
        list: {
          filters: list,
        },
        change: {
          filters: change,
        },
        action: 'count',
      },
      LerRequestCountSchema
    );
  },

  sendMultipleEmailPOST: async (data: ILerRequestEmailNotification) => {
    return client.post(
      `/${endpointEmail}?action=sendToMultipleGroups`,
      data,
      EmailGroupMultiplePayloadSchema
    );
  },

  resendSingleEmailPOST: async (data: ILerRequestSingleEmail) => {
    return client.post(
      `/${endpointEmail}?action=resendToSingleGroup`,
      data,
      EmailGroupSchema
    );
  },

  resendMultipleEmailPOST: async (data: ILerRequestEmailNotification) => {
    return client.post(
      `/${endpointEmail}?action=resendToMultipleGroup`,
      data,
      EmailGroupMultiplePayloadSchema
    );
  },

  emailUserGrpDELETE: async (
    requestId: number,
    versionNo: number,
    groupId: number
  ) => {
    return client.delete(
      `/${endpointEmail}/${groupId}?requestId=${requestId}&version_no=${versionNo}`,
      LerRequestDeletePayloadSchema
    );
  },

  groupDataGET: async (
    requestId: number,
    versionNo: number,
    groupId: number
  ) => {
    return client.get(
      `/${endpointEmail}/${groupId}?requestId=${requestId}&version_no=${versionNo}`,
      {},
      EmailGroupSchema
    );
  },

  approveRejectEmailPUT: async (data: ILerRequestApproveRejectEmail) => {
    return client.put(
      `/${endpointEmail}/${data.groupId}`,
      data,
      EmailGroupSchema
    );
  },

  createSwitchPOST: async (
    sourceId: number,
    data: ILerRequestEtsFormDispatch[]
  ) => {
    return client.post(
      `/${switching}?source=${SwitchingSource.OutageRequest}&sourceId=${sourceId}`,
      data,
      SwitchingCreatePayloadSchema
    );
  },

  addRelatedLerPUT: async (requestId: number, mainRequestId: number) => {
    return client.put(
      `/${endpoint}/${mainRequestId}?action=add-related-outages`,
      { requestId },
      LerRequestPayloadSchema
    );
  },

  removeRelatedLerPUT: async (
    requestIds: Array<number>,
    mainRequestId: number
  ) => {
    return client.put(
      `/${endpoint}/${mainRequestId}?action=remove-related-outages`,
      { requestIds },
      LerRequestPayloadSchema
    );
  },

  sendSystemChangeTasksPOST: async (data: ISystemChangeTasksEmail) => {
    return client.post(
      `/${endpoint}?action=system-change-tasks`,
      data,
      LerRequestPayloadSchema
    );
  },
  autoReconcilePOST: (data: ILerRequest) => {
    return client.post(
      `/${endpointCAISO}?action=reconcile-ler`,
      data,
      SendCaisoSchema
    );
  },

  countFailedSubmittalGET: async () => {
    return client.get(
      `/${endpointCAISO}?action=getPendingSubmittal`,
      {},
      QueryMessageCountSchema
    );
  },
};

export default services;
