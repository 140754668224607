import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/daily-reports.service';
import {
  IDailyReportVoltageGroup,
  IETS,
  IETSList,
  IETSResponsePayload,
  IRadialized,
  IRadializedResponsePayload,
  IETTSPayload,
  ILerReportVoltageGroup,
} from 'src/models/daily-reports.model';
import { DailyReportViewTab } from 'src/constants/daily-reports.constant';
import { LERRequestFormStep } from 'src/constants';
import { format } from 'date-fns';
import Lang from 'src/libraries/language';

export const dailyReportsActionTypes = {
  DAILY_REPORTS_OVERVIEW_LIST: 'DAILY_REPORTS_OVERVIEW_LIST',
  DAILY_REPORTS_OVERVIEW_LIST_UPDATE: 'DAILY_REPORTS_OVERVIEW_LIST_UPDATE',
  DAILY_REPORTS_ETS_LIST: 'DAILY_REPORTS_ETS_LIST',
  DAILY_REPORTS_RADIALIZED_LIST: 'DAILY_REPORTS_RADIALIZED_LIST',
  DAILY_REPORTS_SYSTEM_CHANGES_LIST: 'DAILY_REPORTS_SYSTEM_CHANGES_LIST',
  DAILY_REPORTS_SELECTED_DATE: 'DAILY_REPORTS_SELECTED_DATE',
  DAILY_REPORTS_VIEW_TYPE: 'DAILY_REPORTS_VIEW_TYPE',
  LER_REPORTS_OVERVIEW: 'LER_REPORTS_OVERVIEW',
} as const;

type DailyReport = {
  date: Date;
  viewType: DailyReportViewTab;
};

export const duckActions = {
  listOverviewGET: {
    type: dailyReportsActionTypes.DAILY_REPORTS_OVERVIEW_LIST,
    service: services.listOverviewGET,
    meta: {
      error: (error) => {
        return error.status === 504
          ? Lang.MSG_CAISO_COMMUNICATION_FAILED_RETRIEVE
          : error?.data?.message;
      },
    },
  },
  lerReportGET: {
    type: dailyReportsActionTypes.LER_REPORTS_OVERVIEW,
    service: services.lerReportGET,
    meta: {
      error: (error) => {
        return error.status === 504
          ? Lang.MSG_CAISO_COMMUNICATION_FAILED_RETRIEVE
          : error?.data?.message;
      },
    },
  },
  updatePUT: {
    type: dailyReportsActionTypes.DAILY_REPORTS_OVERVIEW_LIST_UPDATE,
    service: services.updatePUT,
    meta: {
      error: false,
    },
  },
  listETSGET: {
    type: dailyReportsActionTypes.DAILY_REPORTS_ETS_LIST,
    service: services.listETSGET,
  },

  listRadializedGET: {
    type: dailyReportsActionTypes.DAILY_REPORTS_RADIALIZED_LIST,
    service: services.listRadializedGET,
  },

  stashDailyReportDetails: (date: Date, viewTab: DailyReportViewTab) => ({
    type: dailyReportsActionTypes.DAILY_REPORTS_SELECTED_DATE,
    payload: {
      date,
      viewType: viewTab,
    } as DailyReport,
  }),
  stashViewType: (viewTab: DailyReportViewTab) => ({
    type: dailyReportsActionTypes.DAILY_REPORTS_VIEW_TYPE,
    payload: viewTab ?? DailyReportViewTab.Overview,
  }),
};

export type IShortDescriptionAsync = typeof duckActions;

export interface IDailyReportsState
  extends ICommonState<typeof dailyReportsActionTypes> {
  etsList: IETS[];
  etsPayload: IETSResponsePayload;
  radializedPayload: IRadializedResponsePayload;
  radializedList: IRadialized[];
  radializedSubSelectedDate: Date;
  etsSelectedDate: Date;
  overviewDate: Date;
  systemChangesDate: Date;
  viewTab: any;
  overviewList: IDailyReportVoltageGroup[];
  lerReports: ILerReportVoltageGroup[];
  totalSaidiImpact?: number;
}

export const defaultState: IDailyReportsState = {
  status: {},
  overviewList: [],
  lerReports: [],
  etsList: [],
  etsPayload: { out: {}, back: {} },
  radializedPayload: [],
  radializedList: [],
  radializedSubSelectedDate: new Date(),
  etsSelectedDate: new Date(),
  overviewDate: new Date(),
  systemChangesDate: new Date(),
  viewTab: '',
};

const DailyReportsReducer = (
  state: IDailyReportsState,
  action: IReducerAction<IShortDescriptionAsync>
): IDailyReportsState => {
  switch (action.type) {
    case dailyReportsActionTypes.DAILY_REPORTS_ETS_LIST: {
      const etsListResponse: IETS[] = [];
      const getETSDailyReport = (payload: any) => {
        Object.entries<IETSList>(payload ?? {}).map(([, value]) => {
          const etsListVal = JSON.parse(JSON.stringify(value));
          etsListVal.map((val) => {
            const res = val as IETTSPayload;
            etsListResponse.push({
              order_number: [res.sortId],
              location: [res.substationId ?? res.tlSub],
              start_time: [
                `AT ${format(
                  new Date(res.sortTime as unknown as string),
                  'HH:mm'
                )} [${res.outBack?.toUpperCase()}]`,
              ],
              area: [res.subDistId],
              ets: ['___'],
              status: [res.sortStat],
              wo: [res.jobNbr],
              works: [
                `${res.outageFacility?.facility?.outgFacNm}`,
                `${res.outageFacility?.outageType?.outageTypeDesc}`,
              ],
              requestId: res.requestId,
              outBack: res.outBack,
            } as IETS);
          });
        });
      };
      getETSDailyReport(action?.payload?.out);
      getETSDailyReport(action?.payload?.back);
      return {
        ...state,
        etsPayload: action.payload as IETSResponsePayload,
        etsList: etsListResponse ?? [],
      };
    }
    case dailyReportsActionTypes.DAILY_REPORTS_RADIALIZED_LIST: {
      const radializedSub: IRadialized[] = [];
      action.payload?.map((row) => {
        const radSubs: any[] = [];
        const substationCount: any[] = [];
        const tsaid: any[] = [];
        const startTime: any[] = [];
        const stopTime: any[] = [];
        const requestId = row?.radializedSubs?.requestId ?? 0;
        const url = `${
          process.env.REACT_APP_ACTIVE_DIRECTORY_REDIRECT_URI
        }outage-planning?lerEdit=${Math.trunc(requestId)}&active=${
          LERRequestFormStep.SWITCHING
        }`;
        const substationIds =
          row?.radializedSubs?.substationId.split(',') ?? [];
        if (row.splitSaidiCalcInd) {
          substationIds.map((val) => {
            radSubs.push(`${val}#${url}`);
          });
        } else {
          radSubs.push(`${row?.radializedSubs?.substationId}#${url}`);
        }

        substationCount.push(row?.radializedSubs?.substationCnt.custCnt ?? '-');
        tsaid.push(row?.radializedSubs?.saidImpact ?? '-');

        startTime.push(row?.outageDateStop?.startTmTxt ?? '-');
        stopTime.push(row?.outageDateStop?.stopTmTxt ?? '-');
        const etrVal = row?.outageDates?.length
          ? format(
              new Date(row.outageDates[row?.outageDates.length - 1].stopTm),
              'MM/dd HH:mm'
            )
          : '-';
        radSubs.map((val) => {
          radializedSub.push({
            subs: [`${val}`] ?? [],
            start_time: (startTime as any) ?? [],
            stop_time: (stopTime as any) ?? [],
            etr: [etrVal],
            voltage: [(row?.outageFacility?.voltage?.voltNm as any) ?? '-'],
            request_facility: [row?.outageFacility?.facility?.outgFacNm ?? '-'],
            work_description: [(row.wrkDesc as any) ?? '-'],
            recall_time: [row.recalTm ?? '-'],
            contingency_plan: [row.contingencyPlanInd ? 'Yes' : 'No' ?? '-'],
            count: substationCount ?? [],
            tsaidi: tsaid ?? [],
            outageDate: [
              format(new Date(row.outageDateStop.stopTm), 'MM/dd/yyyy') ?? '-',
            ],
            requestId: [`${row.outageFacility.requestId}` ?? '-'],
          } as IRadialized);
        });
      });

      const totalSaidiImpact = radializedSub?.map((val) => {
        return val.tsaidi;
      });
      return {
        ...state,
        radializedPayload: action.payload ?? [],
        radializedList: radializedSub ?? [],
        totalSaidiImpact:
          Number(
            totalSaidiImpact
              .reduce((accumulator, value) => {
                return Number(accumulator) + Number(value);
              }, 0)
              .toFixed(3)
          ) ?? 0,
      };
    }

    case dailyReportsActionTypes.DAILY_REPORTS_SELECTED_DATE: {
      let response: IDailyReportsState = {
        overviewList: [],
        etsList: [],
        lerReports: [],
        etsPayload: { out: {}, back: {} },
        radializedPayload: [],
        radializedList: [],
        radializedSubSelectedDate: new Date(),
        etsSelectedDate: new Date(),
        overviewDate: new Date(),
        systemChangesDate: new Date(),
        status: {},
        viewTab: '',
      };
      switch (action.payload?.viewType) {
        case DailyReportViewTab.ETS: {
          response = {
            ...state,
            etsSelectedDate: action.payload.date,
            viewTab: action.payload.viewType,
          };
          break;
        }
        case DailyReportViewTab.RadializedSubstations: {
          response = {
            ...state,
            radializedSubSelectedDate: action.payload.date,
            viewTab: action.payload.viewType,
            radializedList: state.radializedList,
          };
          break;
        }
        case DailyReportViewTab.SystemChanges: {
          response = {
            ...state,
            systemChangesDate: action.payload.date,
            viewTab: action.payload.viewType,
          };
          break;
        }
        case DailyReportViewTab.Overview: {
          response = {
            ...state,
            overviewDate: action.payload.date,
            viewTab: DailyReportViewTab.Overview,
          };
        }
      }
      return response;
    }
    case dailyReportsActionTypes.DAILY_REPORTS_VIEW_TYPE: {
      return {
        ...state,
        viewTab: action.payload,
      };
    }
    case dailyReportsActionTypes.DAILY_REPORTS_OVERVIEW_LIST: {
      return {
        ...state,
        overviewList: action.payload?.data ?? [],
        status: {
          ...state.status,
          [dailyReportsActionTypes.DAILY_REPORTS_OVERVIEW_LIST]: {
            fetching: false,
            error: action.payload?.error ?? null,
          },
        },
      };
    }
    case dailyReportsActionTypes.LER_REPORTS_OVERVIEW: {
      return {
        ...state,
        lerReports: action.payload?.data ?? [],
        status: {
          ...state.status,
          [dailyReportsActionTypes.LER_REPORTS_OVERVIEW]: {
            fetching: false,
            error: action.payload?.error ?? null,
          },
        },
      };
    }
    // case dailyReportsActionTypes.DAILY_REPORTS_OVERVIEW_LIST_UPDATE: {
    //   if (action.payload) {
    //     const updatedData = action.payload;
    //     // Remove the LER if the main status is not Scheduled
    //     if (updatedData.requestStat !== LERRequestStatus.Scheduled) {
    //       const list = state.overviewList.map((item) => {
    //         const filtered = item;
    //         filtered.dailyReports = filtered.dailyReports.filter((value) => value.requestId !== updatedData.requestId);
    //         return filtered;
    //       });
    //       return { ...state, overviewList: list };
    //     }
    //     const list = state.overviewList.map((item) => {
    //       const filtered = item;
    //       filtered.dailyReports = filtered.dailyReports.map((value) => {
    //         if (value.requestId === updatedData.requestId) {
    //           return updatedData;
    //         }
    //         return value;
    //       });
    //       return filtered;
    //     });
    //     return { ...state, overviewList: list };
    //   }
    //   return state;
    // }
    default: {
      return state;
    }
  }
};

export default DailyReportsReducer;
