/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useCallback } from 'react'; // useEffect,
import {
  // ATMCheckbox,
  ATMHeader,
  ATMIcon,
  IORGDataTableColumn,
  MOLInfoText,
  ORGDataTable,
} from 'shared-it-appmod-ui';
import { LERRequestFormStep } from 'src/constants';
import Lang from 'src/libraries/language';
import { format24hTime, formatDate } from 'src/libraries/moment.library';
import { ILerRequest } from 'src/models/ler-request.model';
import { ISortResponse } from 'src/models/switching.model';
import SwitchThreeWayToggle from 'src/components/organisms/switch/switch-three-way-toggle/switch-three-way-toggle';
import styles from '../../ler-request.module.scss';

type IProps = {
  // Change to Switching Interface once ready.
  data: ILerRequest;
};

export enum SortStatusCompleted {
  COMPLETE = 'COMPLETE',
  COMPLETED = 'COMPLETED',
}

export enum SortStatusCancel {
  CANCELLED = 'CANCELLED',
  CANCELLED_ALTERNATIVE = 'CANCELED',
}

export const isSortStatusComplete = (status: string) => {
  if (status) {
    return (
      status.toUpperCase() === SortStatusCompleted.COMPLETE ||
      status.toUpperCase() === SortStatusCompleted.COMPLETED
    );
  }
  return false;
};

export const isSortStatusCancel = (status: string) => {
  if (status) {
    return (
      status.toUpperCase() === SortStatusCancel.CANCELLED ||
      status.toUpperCase() === SortStatusCancel.CANCELLED_ALTERNATIVE
    );
  }
  return false;
};

const LERRequestSwitchingDetail: React.FC<IProps> = ({ data }) => {
  const [state, setState] = useState<string>('Default');
  const [showToggle, setShowToggle] = useState(false);
  const [options, setOptions] = useState<string[]>(['Default']);

  const columns: IORGDataTableColumn<ISortResponse>[] = [
    {
      title: Lang.LBL_DATE,
      index: 'sortTime',
      render: (value) => formatDate(value),
    },
    {
      title: Lang.LBL_TIME,
      index: 'outBack',
      render: (value, values) => (
        <div>
          <span>{value === 'OUT' ? `${value}  AT` : `${value} AT`}</span>{' '}
          {format24hTime(values.sortTime)}
        </div>
      ),
    },
    {
      title: Lang.LBL_SUB_POLE,
      index: 'tlSub',
    },
    {
      title: Lang.LBL_TL_SUB,
      index: 'subPole',
    },
    {
      title: Lang.LBL_AREA,
      index: 'subDistId',
    },
    {
      title: Lang.LBL_REMARKS,
      index: 'dspchRem',
      // render: (value) => <div className="cellText">{value}</div>,
      render: (value) => (
        <MOLInfoText transparent limit={5} content={value || ''} />
      ),
    },
    {
      title: Lang.LBL_ORDER_NUM,
      index: 'sortId',
    },
    {
      title: Lang.LBL_STATUS,
      index: 'sortStat',
      render: (_, values, _index) => values?.sortStat?.toUpperCase(),
    },
  ];

  const list = data[LERRequestFormStep.SWITCHING] ?? [];
  const checkSortStatus = useCallback(() => {
    const sortStatCompleteValues = Object.values(SortStatusCompleted);
    const withCompleteStatus = list.filter((v) =>
      sortStatCompleteValues
        ?.toString()
        .toUpperCase()
        .includes(v.sortStat?.toUpperCase() as unknown as SortStatusCompleted)
    );

    const sortStatCanceledeValues = Object.values(SortStatusCancel);
    const withCanceledStatus = list.filter((v) =>
      sortStatCanceledeValues
        ?.toString()
        .toUpperCase()
        .includes(v.sortStat?.toUpperCase() as unknown as SortStatusCancel)
    );

    let newOptions = ['Default'];

    if (withCompleteStatus.length > 0) {
      newOptions.push('Completed');
    }

    if (withCanceledStatus.length > 0) {
      newOptions.push('Cancelled');
    }

    if (withCompleteStatus.length > 0 && withCanceledStatus.length > 0) {
      newOptions = ['Default', 'Completed', 'Cancelled'];
    }

    setShowToggle(newOptions.length > 1);
    setOptions(newOptions);
  }, []);

  useEffect(() => {
    checkSortStatus();
  }, [checkSortStatus]);

  const returnList = useCallback(() => {
    switch (state) {
      case 'Completed':
        return list?.filter((val) =>
          isSortStatusComplete(val?.sortStat as string)
        );
      case 'Cancelled':
        return list?.filter((val) =>
          isSortStatusCancel(val?.sortStat as string)
        );
      default:
        return list?.filter(
          (val) =>
            !isSortStatusCancel(val?.sortStat as string) &&
            !isSortStatusComplete(val?.sortStat as string)
        );
    }
  }, [list, state]);

  if (!data) {
    return null;
  }

  return (
    <div>
      <span>
        <ATMIcon circular name="random" />
      </span>
      <ATMHeader
        as="h1"
        content={Lang.TTL_SWITCHING}
        className={styles.headerDoc}
      />
      <ATMHeader as="h2" content={Lang.TTL_ETS_DISPATCH} />
      <ORGDataTable
        columns={columns}
        data={returnList()}
        counter
        rowsPerPageOptions={[20]}
        toolbars={[
          () => {
            if (list && showToggle) {
              return (
                <SwitchThreeWayToggle
                  state={state}
                  setState={setState}
                  options={options}
                  numStates={options.length}
                />
              );
            }
            return '';
          },
        ]}
      />
    </div>
  );
};

export default LERRequestSwitchingDetail;
