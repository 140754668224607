import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/switching.service';
import { orderBy } from 'lodash';
import { ISwitching } from 'src/models/switching.model';

export const switchingActionTypes = {
  SWITCHING_DATA_READ: 'SWITCHING_DATA_READ',
  SWITCHING_LIST_READ: 'SWITCHING_LIST_READ',
  SWITCHING_DATA_SET: 'SWITCHING_DATA_SET',
  SWITCHING_REQUEST_DATA_CREATE_TROUBLE_JOB:
    'SWITCHING_REQUEST_DATA_CREATE_TROUBLE_JOB',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: switchingActionTypes.SWITCHING_DATA_READ,
    service: services.dataGET,
  },

  listGET: {
    type: switchingActionTypes.SWITCHING_LIST_READ,
    service: services.listGET,
  },

  createPOST: {
    type: switchingActionTypes.SWITCHING_REQUEST_DATA_CREATE_TROUBLE_JOB,
    service: services.createPOST,
  },

  // This is a sync action
  setData: (switching: ISwitching) => ({
    type: switchingActionTypes.SWITCHING_DATA_SET,
    payload: switching,
  }),
};

export type ISwitchingAsync = typeof duckActions;

export interface ISwitchingState
  extends ICommonState<typeof switchingActionTypes> {
  data?: ISwitching;
  list: ISwitching[];
  listDropdown: ISwitching[];
  total: number;
}

export const defaultState: ISwitchingState = {
  status: {},
  list: [],
  listDropdown: [],
  total: 0,
};

const SwitchingReducer = (
  state: ISwitchingState,
  action: IReducerAction<ISwitchingAsync>
): ISwitchingState => {
  switch (action.type) {
    case switchingActionTypes.SWITCHING_DATA_SET:
    case switchingActionTypes.SWITCHING_DATA_READ: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case switchingActionTypes.SWITCHING_LIST_READ: {
      const filteredList = action.payload?.rows?.filter((obj, index) => {
        return (
          index ===
          action.payload?.rows?.findIndex(
            (o) => obj.description === o.description
          )
        );
      });
      return {
        ...state,
        list: orderBy(action.payload?.rows, ['description'], ['asc']) ?? [],
        listDropdown: orderBy(filteredList, ['description'], ['asc']) ?? [],
        total: action.payload?.count ?? 0,
      };
    }

    default: {
      return state;
    }
  }
};

export default SwitchingReducer;
