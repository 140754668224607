import classNames from 'classnames';
import React from 'react';
import { ATMLabel } from 'shared-it-appmod-ui';
import {
  EquipmentTypeStatus,
  FacilityStatus,
  KeyHolderType,
  KeyRequestStatus,
  RealTimeLogCategory,
  TroubleTicketPriority,
  TroubleTicketStatus,
  ContractorStatus,
  SubstationTimeStatus,
  TroubleJobStatus,
  LERNotificationStatus,
  RoleEmpHRStatus,
  RoleEmpRoleStatus,
  EmployeeStatus,
  FailedTest,
} from 'src/constants';
import Lang from 'src/libraries/language';
import styles from './list-status.module.scss';

type IProps = {
  language?: Record<string, string>;
  value?:
    | TroubleTicketStatus
    | FacilityStatus
    | EquipmentTypeStatus
    | KeyRequestStatus
    | KeyHolderType
    | RealTimeLogCategory
    | ContractorStatus
    | SubstationTimeStatus
    | TroubleJobStatus
    | RoleEmpHRStatus
    | RoleEmpRoleStatus
    | EmployeeStatus
    | FailedTest
    | string
    | null;
  label?: boolean;
  inline?: boolean;
  time?: boolean;
};

const ListStatus: React.FC<IProps> = ({
  language = Lang.STATUS,
  value,
  label = false,
  inline = false,
  time = false,
}) => {
  if (!value) {
    return null;
  }
  let type = 'danger';

  switch (value) {
    case EquipmentTypeStatus.ACTIVE:
    case KeyRequestStatus.APPROVED:
    case LERNotificationStatus.APPROVED:
    case KeyHolderType.EMPLOYEE:
    case KeyHolderType.CONTRACTOR:
    case ContractorStatus.ACTIVE:
    case SubstationTimeStatus.LESS_THAN_A_DAY:
    case RoleEmpHRStatus.ACTIVE:
    case RoleEmpRoleStatus.ACTIVE:
    case TroubleJobStatus.CLOSED:
    case EmployeeStatus.ACTIVE: {
      type = 'success';
      break;
    }

    case RealTimeLogCategory.COMMFAILURE:
    case RealTimeLogCategory.COMPTOOLS:
    case RealTimeLogCategory.WILDFIRE:
    case EquipmentTypeStatus.INACTIVE:
    case EmployeeStatus.INACTIVE:
    case ContractorStatus.INACTIVE:
    case RoleEmpHRStatus.INACTIVE:
    case RoleEmpRoleStatus.INACTIVE:
    case FailedTest.FAILED:
    case TroubleTicketStatus.OPEN:
    case SubstationTimeStatus.NOT_CHECKOUT_WITHIN_THE_DAY:
    case KeyRequestStatus.REJECTED:
    case TroubleJobStatus.OPEN: {
      type = 'danger';
      break;
    }

    case KeyRequestStatus.PENDINGAPPROVAL:
    case LERNotificationStatus.REJECTED:
    case TroubleTicketPriority.HIGH:
    case SubstationTimeStatus.PAST_MIDNIGHT:
    case TroubleJobStatus.DEVELOPING: {
      type = 'warning';
      break;
    }

    case TroubleTicketPriority.EMERGENCY: {
      type = 'emergency';
      break;
    }

    case TroubleTicketStatus.ACTIVE: {
      type = 'activeTT';
      break;
    }

    case TroubleTicketStatus.CLOSED: {
      type = 'closedTT';
      break;
    }

    case RealTimeLogCategory.REALTIMEREQUEST:
    case FacilityStatus.FUTURE:
    case TroubleTicketPriority.NORMAL: {
      type = 'future';
      break;
    }
    case RealTimeLogCategory.EXTASSISTANCE:
    case RealTimeLogCategory.CHANGECONTROL:
    case RealTimeLogCategory.RCBANOTIF:
    case RealTimeLogCategory.INTFREQEXCUR:
    case RealTimeLogCategory.FIELDNOTIF:
    case RealTimeLogCategory.GENOPERATING:
    case RealTimeLogCategory.NIGHTSHIFT:
    case RealTimeLogCategory.TSOSTAFFING:
    case LERNotificationStatus.PENDING:
    case SubstationTimeStatus.OUT: {
      type = 'basic';
      break;
    }
    case RealTimeLogCategory.RCOPERATING:
    case RealTimeLogCategory.BAOPERATING:
    case RealTimeLogCategory.EMERGENCYEVENT:
    case RealTimeLogCategory.FORCEDOUTAGE: {
      type = 'warning-yellow';
      break;
    }
  }

  if (!language) {
    return (
      <ATMLabel className={classNames(styles.status, styles[type])}>
        {value}
      </ATMLabel>
    );
  }

  if (label) {
    return (
      <ATMLabel
        className={
          inline
            ? `${classNames(styles.status, styles[type], styles.inline)}`
            : classNames(styles.status, styles[type])
        }
      >
        {language[value]}
      </ATMLabel>
    );
  }

  if (time) {
    return (
      <ATMLabel
        className={
          inline
            ? `${classNames(styles.status, styles[type], styles.inline)}`
            : classNames(styles.status, styles[type])
        }
      >
        {language[value]}
      </ATMLabel>
    );
  }

  return (
    <ATMLabel
      className={`${classNames(styles.label, styles[type])} horizontal`}
    >
      {language[value]}
    </ATMLabel>
  );
};

export default ListStatus;
