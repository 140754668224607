import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { unitOfTime } from 'moment-timezone';
import { ATMButton, ATMIcon, ATMLoader, ATMPopover } from 'shared-it-appmod-ui';
import { Dimmer, SemanticICONS } from 'semantic-ui-react';
import Moment, { IMoment } from 'src/libraries/moment.library';
import { ICalendarEvent } from 'src/libraries/calendar.library';
import Lang from 'src/libraries/language';
import {
  IOutageDateCalendar, IOutageDateFilter,
} from 'src/models/calendar-outage.model';
import { getOutageCalendarEvents } from 'src/helpers/outage-dates.helper';
import { PAGE_LIMIT_THRESHOLD } from 'src/constants';
import { useLimitPageItems } from 'src/hooks/limit.hook';
import { IEventSegment } from '../calendar-event/calendar-event.component';
import styles from './calendar-timeline.module.scss';
import CalendarTimelineCell, {
  CELL_SIZE,
} from './calendar-timeline-cell.component';

export type ICalendarResource = {
  title:
    | ((
        event: ICalendarEvent<IOutageDateCalendar>,
        week: IMoment[]
      ) => React.ReactNode)
    | React.ReactNode;
  subTitle?:
    | ((
        event: ICalendarEvent<IOutageDateCalendar>,
        week: IMoment[]
      ) => React.ReactNode)
    | React.ReactNode;
  resourceId: any;
  children?: ICalendarResource[];
  besInd?: string;
  isParent?: boolean;
  levels?: IEventSegment<ICalendarEvent<IOutageDateCalendar>>[][];
  significantCost?: string;
  specialConcerns?:
    | ((
        event: ICalendarEvent<IOutageDateCalendar>,
        week: IMoment[]
      ) => React.ReactNode)
    | React.ReactNode;
};

type ICalendarTimeline = {
  date?: IMoment | Date;
  interval?: [number, unitOfTime.Base];
  events: ICalendarEvent<IOutageDateCalendar>[];
  resources: ICalendarResource[];
  loading?: boolean;
  handleHover?: (event: IMoment, isChecked: boolean) => void;
  onChange?: (dates: IMoment) => void;
  sortBy?: IOutageDateFilter;
  setSortBy?: React.Dispatch<React.SetStateAction<IOutageDateFilter>>;
};

const CalendarTimeline: React.FC<ICalendarTimeline> = ({
  events,
  resources,
  date,
  interval = [1, 'month'],
  loading = false,
  onChange,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const [startDate, setStartDate] = useState(
    date ? Moment(date).startOf('day') : Moment().startOf('month')
  );
  const endDate = useMemo(
    () =>
      startDate
        .clone()
        .add(...interval)
        .endOf('day'),
    [startDate, interval]
  );

  const [hidden, setHidden] = useState<Record<any, boolean>>({});
  const [limit, setLimit] = useLimitPageItems();

  useEffect(() => {
    setLimit(PAGE_LIMIT_THRESHOLD);
  }, [resources, setLimit]);

  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current && bodyRef.current) {
        headerRef.current.scrollLeft = bodyRef?.current?.scrollLeft;
      }
    };

    bodyRef.current?.addEventListener('scroll', handleScroll);

    return () => {
      bodyRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setStartDate(
      date ? Moment(date).startOf('day') : Moment().startOf('month')
    );
  }, [date, setStartDate]);

  const handleNavigation = useCallback(
    (start) => {
      if (onChange) {
        onChange(start);
      }

      setStartDate(start);
    },
    [onChange, setStartDate]
  );

  const calendar = useMemo(() => {
    const dates: IMoment[] = [];
    const tempDate = startDate.clone().subtract(1, 'day');
    while (tempDate.isBefore(endDate, 'day')) {
      dates.push(tempDate.add(1, 'day').clone());
    }

    return dates;
  }, [startDate, endDate]);

  const list = useMemo(
    () => getOutageCalendarEvents(resources, events, calendar),
    [events, resources, calendar]
  );

  const totalMonths =
    endDate
      .clone()
      .startOf('month')
      .diff(startDate.clone().startOf('month'), 'month') + 1;

  const calendarMonths = useMemo(
    () => calendar.map((val) => val.format('MMMM-YYYY')),
    [calendar]
  );

  return (
    <div className={styles.timeline}>
      <table style={{ width: '100%' }}>
        <colgroup>
          <col width={20} />
          <col width={2} />
          <col width={76} />
          <col width={2} />
        </colgroup>
        <tbody>
          <tr className={styles.timelineSection}>
            <td>
              <div className={styles.leftGridPanel}>
                <table>
                  <thead className={styles.header}>
                    <tr>
                      <th>KV</th>
                      <th>
                        <span style={{ marginRight: '20px' }}>
                          Job Location
                        </span>
                        <br />
                        <span className={styles.besInfo}>
                          <small style={{ fontSize: '60%' }}>
                            BES Facility (red), Special Concerns (bold)
                          </small>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...list]
                      .slice(0, limit)
                      .filter((v) => !hidden[v.parentId])
                      .map((value) => (
                        <tr
                          key={value.resourceId}
                          onClick={() =>
                            setHidden((values) => ({
                              ...values,
                              [value.resourceId]:
                                !values[value.resourceId] ?? true,
                            }))
                          }
                        >
                          {!value.parentId ? (
                            <td
                              style={{
                                height: CELL_SIZE * (value.levels?.length || 1),
                              }}
                              className={classNames(styles.groupTitle)}
                              colSpan={2}
                            >
                              <div className={styles.title} role="button">
                                <span>
                                  {value.title?.toString().concat(' ', 'KV')}
                                </span>
                                <span>
                                  <ATMIcon
                                    size="mini"
                                    name={
                                      `chevron ${
                                        hidden[value.resourceId] ? 'up' : 'down'
                                      }` as SemanticICONS
                                    }
                                  />
                                </span>
                              </div>
                            </td>
                          ) : (
                            <>
                              <td
                                style={{
                                  height:
                                    CELL_SIZE * (value.levels?.length || 1),
                                }}
                                className={classNames(styles.rowTitle)}
                              >
                                {value.specialConcerns ? (
                                  <span className={styles.specialConcerns}>
                                    <ATMPopover
                                      trigger={
                                        <ATMIcon
                                          name="info circle"
                                          color="yellow"
                                          // circular
                                        />
                                      }
                                      content={value.specialConcerns}
                                      position="top right"
                                      inverted
                                      size="mini"
                                    />
                                  </span>
                                ) : (
                                  <span />
                                )}
                                {value.subTitle !== 'None'
                                  ? value.subTitle
                                  : ''}
                              </td>
                              <td
                                style={{
                                  height:
                                    CELL_SIZE * (value.levels?.length || 1),
                                }}
                                className={styles.rowSubtitle}
                              >
                                <div
                                  style={{
                                    color: `${value?.besInd ? 'red' : ''}`,
                                  }}
                                  title={
                                    typeof value.title === 'string'
                                      ? // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                                        (value.title as string)
                                      : undefined
                                  }
                                >
                                  {value.specialConcerns ? (
                                    <b>{value.title}</b>
                                  ) : (
                                    value.title
                                  )}
                                </div>
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </td>
            <td>
              <ATMButton
                className={styles.arrowNav}
                icon="angle double left"
                onClick={() =>
                  handleNavigation(
                    startDate.clone().subtract(interval[0] + 1, interval[1])
                  )
                }
              />
            </td>
            <td>
              <div className={styles.wrapper}>
                <div ref={headerRef} className={styles.timelineHeader}>
                  <table
                    className={classNames(
                      styles.tableGrid,
                      styles.header,
                      styles.calendarHeader
                    )}
                    style={{ minWidth: calendar.length * CELL_SIZE }}
                  >
                    <thead>
                      <tr>
                        {Array(totalMonths)
                          .fill(null)
                          .map((_, index) => {
                            const month = startDate.clone().add(index, 'month');

                            return (
                              <th
                                key={index}
                                colSpan={
                                  calendarMonths.filter(
                                    (v) => v === month.format('MMMM-YYYY')
                                  ).length
                                }
                                className={styles.borderRight}
                                align="left"
                              >
                                <div className={styles.monthYear}>
                                  {month.format('MMMM YYYY')}
                                </div>
                              </th>
                            );
                          })}
                      </tr>
                      <tr>
                        {calendar.map((v) => (
                          <th
                            key={v.format('MM-DD-YYYY')}
                            style={{ height: CELL_SIZE }}
                            className={classNames(styles.day, {
                              [styles.cellDivider]: v.isSame(
                                v.clone().endOf('month'),
                                'day'
                              ),
                            })}
                          >
                            {v.format('D')}
                          </th>
                        ))}
                      </tr>
                      <tr>
                        {calendar.map((v) => (
                          <th
                            key={v.format('MM-DD-YYYY')}
                            style={{ height: CELL_SIZE }}
                            className={classNames({
                              [styles.cellDivider]: v.isSame(
                                v.clone().endOf('month'),
                                'day'
                              ),
                            })}
                          >
                            {calendar.length > 7
                              ? v.format('ddd').charAt(0).toUpperCase()
                              : v.format('dddd')}
                          </th>
                        ))}
                      </tr>
                    </thead>
                  </table>
                </div>
                <div ref={bodyRef} className={styles.timelineBody}>
                  <div
                    className={styles.timelineSlot}
                    style={{ minWidth: calendar.length * CELL_SIZE }}
                  >
                    {loading && (
                      <Dimmer active inverted>
                        <ATMLoader inverted content={Lang.LBL_LOADING} />
                      </Dimmer>
                    )}

                    <div className={styles.timelineBg}>
                      <table className={styles.tableGrid}>
                        <tbody>
                          <tr>
                            {calendar.map((val, key) => (
                              <td
                                key={key}
                                className={classNames(styles.slot, {
                                  [styles.slotMinor]: !val.isSame(
                                    val.clone().endOf('isoWeek'),
                                    'day'
                                  ),
                                })}
                              >
                                <div />
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <table className={styles.tableEvent}>
                      <tbody>
                        {list.length ? (
                          [...list]
                            .slice(0, limit)
                            .filter((v) => !hidden[v.parentId])
                            .map((value) => {
                              return (
                                <CalendarTimelineCell
                                  key={`${value.resourceId}`}
                                  value={value}
                                  calendar={calendar}
                                />
                              );
                            })
                        ) : (
                          <tr>
                            <td
                              className={styles.noData}
                              colSpan={calendar.length}
                            >
                              <ATMIcon name="warning circle" />
                              {Lang.MSG_OUTAGE_CALENDAR_NO_DATA}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <ATMButton
                className={styles.arrowNav}
                icon="angle double right"
                onClick={() => {
                  handleNavigation(
                    startDate.clone().add(interval[0] + 1, interval[1])
                  );
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CalendarTimeline;
