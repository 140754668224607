import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import { realTimeLogActionTypes } from 'src/ducks/real-time-log.duck';
import {
  IRealTimeLog,
  IRealTimeLogEntryForm,
} from 'src/models/real-time-log.model';
import {
  getRealTimeLogParams,
  getRealTimeLogStatus,
} from 'src/selectors/real-time-log.selector';
import history from 'src/history';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import Lang from 'src/libraries/language';
import { useSubstationContext } from 'src/contexts/substation.context';
import { useEquipmentTypeContext } from 'src/contexts/equipment-type.context';
import { RealTimeLogCategory } from 'src/constants/real-time-log.constant';
import { getUser } from 'src/libraries/amplify.library';
import { addDays, isBefore } from 'date-fns';
import RealTimeLogEditView from './real-time-log-edit.view';

type IProp = {
  data: IRealTimeLog;
  showTableAll?: boolean;
  setShowTableAll?: (x: boolean) => void;
  searchFilters?: Record<any, any>;
  setSearchFilters?: React.Dispatch<React.SetStateAction<Record<any, any>>>;
  currentTab?: number;
};

const RealTimeLogEdit: React.FC<IProp> = ({
  data,
  showTableAll,
  setShowTableAll,
  searchFilters,
  setSearchFilters,
  currentTab,
}) => {
  const { state, actions } = useRealTimeLogContext();
  const { actions: substationActions } = useSubstationContext();
  const { actions: equipmentTypeActions } = useEquipmentTypeContext();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [tripOnEdit, setTripOnEdit] = useState<{
    index: number;
    logSub: string;
    tripsTargets: string;
  } | null>(null);
  const [isChangeLogUpdate, setIsChangeLogUpdate] = useState(false);
  const [changeLogUpdateComplete, setChangeLogUpdateComplete] =
    useState<boolean>(true);

  // const [isForcedOutageSubmitted, setIsForcedOutageSubmitted] = useState(false);
  // const [isForcedOutageConfirmOpen, setIsForcedOutageConfirmOpen] =
  //   useState(false);

  const formRef = useRef<HTMLFormElement>(null);

  const status = getRealTimeLogStatus(
    state,
    realTimeLogActionTypes.REAL_TIME_LOG_DATA_UPDATE
  );

  useEffect(() => {
    if (!isEditOpen && currentTab === 0) {
      history.push('/real-time-log?realTimeLogStatus=0');
    }
  }, [isEditOpen]);

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IRealTimeLogEntryForm) => {
      const updatedForm: IRealTimeLogEntryForm = formData;

      if (isChangeLogUpdate === true) {
        setIsChangeLogUpdate(false);

        const response = await actions.updatePUT(updatedForm.logId as number, {
          ...updatedForm,
        });

        if (!response.error) {
          setIsEditOpen(false);
          ToastSuccess(Lang.MSG_REAL_TIME_LOG_NOTE_EDIT);

          const readResult = await actions?.listGET(searchFilters);

          if (readResult.error) {
            ToastError('Error retrieving the updated list / record.');
          }
          setChangeLogUpdateComplete(true);
          // if (setLoadingChangeLogUpdate) {
          //   setLoadingChangeLogUpdate(false);
          // }
        } else {
          ToastError('Error updating Real Time Log Note.');
        }
      } else {
        if (formData.logCategory !== RealTimeLogCategory.FORCEDOUTAGE) {
          updatedForm.logDtTmFrcdOutOcurred = null;
          updatedForm.logDtTmFrcdOutRestored = null;
          updatedForm.logTmFrcdOutOccurred = null;
          updatedForm.logTmFrcdOutRestored = null;
        } else {
          if (updatedForm.logDtTmFrcdOutOcurred) {
            updatedForm.logDtTmFrcdOutOcurred = new Date(
              formData.logDtTmFrcdOutOcurred?.getFullYear() as number,
              formData.logDtTmFrcdOutOcurred?.getMonth() as number,
              formData.logDtTmFrcdOutOcurred?.getDate() as number,
              Number(formData.logTmFrcdOutOccurred?.split(':')[0]),
              Number(formData.logTmFrcdOutOccurred?.split(':')[1])
            );
          }

          if (updatedForm.logDtTmFrcdOutRestored) {
            updatedForm.logDtTmFrcdOutRestored = new Date(
              formData.logDtTmFrcdOutRestored?.getFullYear() as number,
              formData.logDtTmFrcdOutRestored?.getMonth() as number,
              formData.logDtTmFrcdOutRestored?.getDate() as number,
              Number(formData.logTmFrcdOutRestored?.split(':')[0]),
              Number(formData.logTmFrcdOutRestored?.split(':')[1])
            );
          }
        }

        updatedForm.logDtTm = new Date(
          formData.logDtTm.getFullYear(),
          formData.logDtTm.getMonth(),
          formData.logDtTm.getDate(),
          Number(formData.logTm.split(':')[0]),
          Number(formData.logTm.split(':')[1])
        );

        // Ensure that updatedBy is the currently logged user.
        updatedForm.updatedBy = getUser()?.emp_no;

        if (tripOnEdit) {
          updatedForm.forcedLog[tripOnEdit.index].logSub = tripOnEdit.logSub;
          updatedForm.forcedLog[tripOnEdit.index].tripsTargets =
            tripOnEdit.tripsTargets;
        }

        setTripOnEdit(null);

        const response = await actions.updatePUT(data.logId, {
          ...updatedForm,
        });

        if (!response.error) {
          // const reportAttachment: string[] = [];
          // const withAttachment = true;
          // actions.sendEmail(response?.payload?.logId ?? -1, { base64String: withAttachment ? reportAttachment : null });
          setIsEditOpen(false);
          ToastSuccess(Lang.MSG_REAL_TIME_LOG_EDIT);

          if (showTableAll) {
            history.push('/real-time-log?realTimeLogStatus=1');
            await actions?.listGET(searchFilters);
          } else {
            const logEntryDate = new Date(updatedForm.logDtTm);
            const todayMinusOne = addDays(new Date(), -1);

            if (isBefore(logEntryDate, todayMinusOne)) {
              const searchCriteria = getRealTimeLogParams(
                {
                  page: 1,
                },
                1
              );
              if (setShowTableAll) {
                setShowTableAll(true);
              }

              if (setSearchFilters) {
                setSearchFilters(searchCriteria);
              }

              history.push('/real-time-log?realTimeLogStatus=1');
              await actions?.listGET(searchCriteria);
            } else {
              history.push('/real-time-log?realTimeLogStatus=0');
            }
          }
        } else {
          ToastError('Error updating Real Time Log.');
        }
      }
    },
    [
      data,
      actions,
      setIsEditOpen,
      isChangeLogUpdate,
      setIsChangeLogUpdate,
      setTripOnEdit,
    ]
  );

  useEffect(() => {
    if (isEditOpen) {
      // substationActions?.listGET({ limit: 0, page: 1 });
      substationActions?.listSubstationCreateGET({ limit: 0, page: 1 });
      if (data.substation?.substationId) {
        equipmentTypeActions?.listGET({
          limit: 0,
          page: 1,
          filters: [
            { name: 'substationId', value: data.substation?.substationId },
          ],
        });
      }
      //   equipmentTypeActions?.listGET({ limit: 0, page: 1 });
      if (!state.line?.length || state.line?.length === 0) {
        // console.log('Logging: real-time edit, fetching line');
        actions?.lineGET();
      }
    }
  }, [isEditOpen, setIsEditOpen]);

  // const handleForcedOutageEmail = useCallback(() => {
  //   // call backend in here
  //   // if (!response.error) {
  //   // }
  // }, []);

  // const clearForcedOutageNotice = useCallback(() => {
  //   // call backend in here
  //   // if (!response.error) {
  //   // }
  // }, []);

  return (
    <RealTimeLogEditView
      formRef={formRef}
      defaultValues={data}
      loading={status.fetching}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      isEditOpen={isEditOpen}
      handleOpen={setIsEditOpen}
      setIsChangeLogUpdate={setIsChangeLogUpdate}
      changeLogUpdateComplete={changeLogUpdateComplete}
      setChangeLogUpdateComplete={setChangeLogUpdateComplete}
      setTripOnEdit={setTripOnEdit}
      // handleForcedOutageEmail={handleForcedOutageEmail}
      // clearForcedOutageNotice={clearForcedOutageNotice}
      // // isForcedOutageSubmitted={isForcedOutageSubmitted}
      // // isForcedOutageConfirmOpen={isForcedOutageConfirmOpen}
      // // setIsForcedOutageConfirmOpen={setIsForcedOutageConfirmOpen}
    />
  );
};

export default RealTimeLogEdit;
