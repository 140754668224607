import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { SwitchingSource } from 'src/constants';
import { httpClient } from 'src/libraries/http.library';
import {
  ISwitchingCreate,
  SwitchingCreatePayloadSchema,
  SwitchingListPayloadSchema,
  SwitchingPayloadSchema,
} from 'src/models/switching.model';

const client = httpClient();
const endpoint = 'switching';
const endpointRequest = 'switching-request';

const services = {
  dataGET: async (id: string) => {
    return client.get(`/${endpoint}/${id}`, {}, SwitchingPayloadSchema);
  },

  listGET: async (params?: Partial<IORGDataTableQueryState>) => {
    return client.get(`/${endpoint}`, params, SwitchingListPayloadSchema);
  },

  createPOST: async (
    sourceId: number,
    data: ISwitchingCreate,
    source = SwitchingSource.TroubleJob
  ) => {
    return client.post(
      `/${endpointRequest}?source=${source}&sourceId=${sourceId}`,
      data,
      SwitchingCreatePayloadSchema
    );
  },
};

export default services;
