import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, Router } from 'react-router-dom';
import * as dotenv from 'dotenv';
import 'src/assets/styles/main.scss';
import history from 'src/history';
import routes from 'src/router';
import * as serviceWorker from './serviceWorker';
import App from './app';

// Initialize environment variables from ~/.env file
dotenv.config();

ReactDOM.render(
  <App>
    <React.StrictMode>
      <Router history={history}>
        <Switch>{routes}</Switch>
      </Router>
    </React.StrictMode>
  </App>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
