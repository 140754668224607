import React, { useState } from 'react';
import { ATMButton, ATMModal } from 'shared-it-appmod-ui';
import { convertKeyNumberToFiveDigits } from 'src/helpers/key-request.helper';
import {
  IIssuedKey,
  IIssuedKeyDetail,
  IIssuedKeyForm,
} from 'src/models/issued-key.model';
import IssuedKeyForm from '../issued-key-form/issued-key-form.component';
import style from './issued-key-edit.module.scss';

type IProps = {
  defaultValues: Partial<IIssuedKey>;
  isOpen: boolean;
  loading: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  handleClick: () => void;
  handleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (data: IIssuedKeyForm) => void;
  statusEmployee: boolean;
  statusKeyDetails: boolean;
  isKeyRequest: boolean;
};

const IssuedKeyEditView: React.FC<IProps> = ({
  defaultValues,
  isOpen,
  loading,
  formRef,
  handleOpen,
  handleClick,
  handleSubmit,
  statusEmployee,
  statusKeyDetails,
  isKeyRequest,
}) => {
  const [isDirty, setIsDirty] = useState(false);

  return (
    <ATMModal
      open={isOpen}
      size="small"
      trigger={
        <ATMButton
          className={style.manageBtn}
          size="medium"
          content="Manage"
          onClick={() => handleOpen(true)}
        />
      }
    >
      <ATMModal.Header>
        Manage - Key{' '}
        {(statusEmployee || statusKeyDetails) && defaultValues ? '' : defaultValues.keyNbr && convertKeyNumberToFiveDigits(defaultValues.keyNbr)}
      </ATMModal.Header>
      <ATMModal.Content>
        {statusEmployee || statusKeyDetails ? (
          <> Loading... Please wait</>
        ) : (
          <IssuedKeyForm
            defaultValues={{
              keyNbr: defaultValues.keyNbr,
              keyTypId: defaultValues.keyTypId,
              reason: undefined,
              stat: defaultValues.stat,
            }}
            formRef={formRef}
            handleSubmit={handleSubmit}
            handleEnable={(value) => setIsDirty(value)}
            keyDetails={defaultValues.keyDetails as unknown as IIssuedKeyDetail}
            isKeyRequest={isKeyRequest}
          />
        )}
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content="Cancel"
          onClick={() => handleOpen(false)}
        />
        <ATMButton
          primary
          content="Save"
          disabled={!isDirty || loading}
          onClick={handleClick}
          loading={loading}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default IssuedKeyEditView;
