import React, { useCallback } from 'react';
import { ATMButton } from 'shared-it-appmod-ui';
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';
import { IRadialized, IRadializedReport } from 'src/models/daily-reports.model';
import Lang from 'src/libraries/language';
import Moment from 'src/libraries/moment.library';

type IProps = {
  fileData: IRadialized[];
  fileName?: string;
};

const DailyReportRadializedDownloader: React.FC<IProps> = ({
  fileData,
  fileName,
}) => {
  const generateReportData = (reportData: IRadializedReport) => {
    const data: any[] = [];
    reportData.data?.map((val) => {
      const entries = JSON.parse(JSON.stringify(val.data));
      const rsDataVal: string[] = new Array(val.length);
      let keys = '';
      Object.entries(entries).map((index, _value) => {
        const indexValue = index as any;
        const pos = indexValue[0] as number;
        // eslint-disable-next-line prefer-destructuring
        rsDataVal[pos] = indexValue[1];
        keys += `${pos},`;
      });
      for (let i = 0; i < val.length; i++) {
        if (keys.search(i as any) === -1) {
          rsDataVal[i] = '';
        }
      }
      data.push(rsDataVal);
    });
    return data;
  };

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const extension = '.xls';

  const getCell = (row: any, col: any): any => {
    const cellRef = XLSX.utils.encode_cell({ r: row, c: col });
    return cellRef;
  };

  const removeDash = (val) => {
    if (val === '-') {
      return '';
    }
    return val;
  };

  const handleDataExport = useCallback(async () => {
    const extractedData: any[] = [];
    const dates: any = [];
    let totalTSaidiImpact = 0.0;
    const extactRadializedReport = () => {
      fileData.map((val) => {
        let substation = '';
        val.subs?.map((sub) => {
          substation += `${sub?.split('#')[0]}`;
        });

        let start_time = '';
        val.start_time?.map((v) => {
          start_time += removeDash(v);
        });
        let stop_time = '';
        val.stop_time?.map((v) => {
          stop_time += removeDash(v);
        });
        let volt = '';
        val.voltage?.map((v) => {
          volt += removeDash(v);
        });
        let request_facility = '';
        val.request_facility?.map((v) => {
          request_facility += removeDash(v);
        });
        let work_desc = '';
        val.work_description?.map((v) => {
          work_desc += v;
        });
        let recall_time = '';
        val.recall_time?.map((v) => {
          recall_time += removeDash(v);
        });
        let cust_count = '';
        val.count?.map((v) => {
          cust_count += removeDash(v);
        });
        let saidi_impact = '';
        val.tsaidi?.map((v) => {
          saidi_impact += v;
        });
        totalTSaidiImpact += parseFloat(saidi_impact);
        dates.push(val.outageDate);
        extractedData.push({
          'Subs Radialized': `${substation}`,
          'Request ID': `${val.requestId}`,
          'Start Time': `${start_time}`,
          'Stop Time': `${stop_time}`,
          Voltage: `${volt}`,
          'Request Facility': `${request_facility}`,
          'Work Description': `${work_desc}`,
          'Recall Time (Hrs)': `${recall_time}`,
          'Customer Count': `${cust_count}`,
          'Possible T-SAIDI Impact': `${val.tsaidi}`,
        });
      });
    };

    extactRadializedReport();
    const ws = XLSX.utils.book_new();
    const radializedFedSubstations: IRadializedReport = {
      data: [
        {
          length: 1,
          data: {
            0: `Radially Fed Substations for ${dates.length ? dates[0] : ''}`,
            1: '',
            2: '',
            3: '',
            4: '',
            5: '',
            6: '',
            7: '',
            8: '',
            9: '',
          } as any,
        },
      ],
    };
    const reportData: IRadializedReport = {
      data: [
        {
          length: 10,
          data: {
            0: 'Subs Radialized',
            1: 'Request ID',
            2: 'Start Time',
            3: 'Stop Time',
            4: 'Voltage',
            5: 'Request Facility',
            6: 'Work Description',
            7: 'Recall',
            8: 'Customer',
            9: 'Possible',
          } as any,
        },
        {
          length: 10,
          data: {
            7: 'Time',
            8: 'Count',
            9: 'T-SAIDI',
          } as any,
        },
        {
          length: 10,
          data: {
            7: '(Hrs)',
            9: 'Impact',
          } as any,
        },
      ],
    };
    const tSaidiImpactContent: IRadializedReport = {
      data: [
        {
          length: 10,
          data: {
            8: 'Total',
          } as any,
        },
        {
          length: 10,
          data: {
            8: 'Possible',
          } as any,
        },
        {
          length: 10,
          data: {
            8: 'T-SAIDI',
          } as any,
        },
        {
          length: 10,
          data: {
            8: 'Impact',
            9: `${totalTSaidiImpact.toFixed(3)}`,
          } as any,
        },
      ],
    };
    const RadializedFed = generateReportData(radializedFedSubstations);
    const TSaidi = generateReportData(tSaidiImpactContent);
    const Heading = generateReportData(reportData);
    XLSX.utils.sheet_add_aoa(ws, RadializedFed, { origin: 'A1' });
    XLSX.utils.sheet_add_json(ws, Heading, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_json(ws, extractedData, {
      origin: 'A5',
      skipHeader: true,
    });

    XLSX.utils.sheet_add_json(ws, TSaidi, {
      origin: -1,
      skipHeader: true,
    });

    const cells: any[] = [];
    const toBeMergedCells: any[] = [];
    const tableLastCells: any[] = [];
    const range = XLSX.utils.decode_range(ws['!ref'] ?? '');

    const wscols = [
      { wch: 10 },
      { wch: 10 },
      { wch: 15 },
      { wch: 15 },
      { wch: 6 },
      { wch: 25 },
      { wch: 20 },
    ];
    ws['!cols'] = wscols;

    for (let row = 0; row <= range.e.r; row++) {
      for (let col = 0; col <= range.e.c; col++) {
        if (row === 0 && col < 4) {
          toBeMergedCells.push({ s: { c: 0, r: 0 }, e: { c: 2, r: 0 } });
        }
        if (row === 1 || row === 2 || row === 3) {
          if (col <= 6) {
            toBeMergedCells.push({ s: { c: col, r: 1 }, e: { c: col, r: 3 } });
          }
        }
        if (
          row === range.e.r ||
          row === range.e.r - 1 ||
          row === range.e.r - 2 ||
          row === range.e.r - 3
        ) {
          if (col < 8) {
            toBeMergedCells.push({
              s: { c: col, r: range.e.r - 3 },
              e: { c: col, r: range.e.r },
            });
          }
          tableLastCells.push(getCell(row, col));
        } else {
          cells.push(getCell(row, col));
        }
      }
    }
    ws['!merges'] = toBeMergedCells;

    const headers = [
      {
        nonWrapText: [
          'A1',
          'G2',
          'H2',
          'I2',
          'H3',
          'I3',
          'H4',
          'I4',
          'J2',
          'J3',
          'J4',
        ],
        wrappedText: ['A2', 'B2', 'C2', 'D2', 'E2', 'F2', 'G2'],
      },
    ];

    cells.map((cell) => {
      ws[cell].s = {
        font: { bold: false, name: 'Calibri', sz: '11' },
        fill: {
          patternType: 'solid',
          fgColor: { rgb: 'FFFFFFFF' },
          bgColor: { rgb: 'FFFFFFFF' },
        },
      };
    });

    tableLastCells.map((cell) => {
      ws[cell].s = {
        font: { bold: true, name: 'Calibri', sz: '11' },
        fill: {
          patternType: 'solid',
          fgColor: { rgb: 'DDDDDDDD' },
          bgColor: { rgb: 'DDDDDDDD' },
        },
      };
    });

    headers.map((val) => {
      val.nonWrapText.map((nonWrap) => {
        ws[nonWrap].s = {
          font: { bold: true, name: 'Calibri', sz: '11' },
          fill: {
            patternType: 'solid',
            fgColor: { rgb: 'FFFFFFFF' },
            bgColor: { rgb: 'FFFFFFFF' },
          },
        };
      });

      val.wrappedText.map((wrapText) => {
        ws[wrapText].s = {
          alignment: {
            wrapText: '1',
            vertical: 'center',
            horizontal: 'center',
          },
          font: { bold: true, name: 'Calibri', sz: '11' },
          fill: {
            patternType: 'solid',
            fgColor: { rgb: 'FFFFFFFF' },
            bgColor: { rgb: 'FFFFFFFF' },
          },
        };
      });
    });

    const wb = {
      Sheets: { viewRadializedSubs: ws },
      SheetNames: ['viewRadializedSubs'],
    };

    const buffer = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
      cellStyles: true,
    });
    const data = new Blob([buffer], { type: fileType });
    await FileSaver.saveAs(
      data,
      `${
        fileName ?? `MCC_Radialized_Substations_${Moment().format('YYYYMMDD')}`
      }${extension}`
    );
  }, [fileData, fileName]);
  return (
    <div>
      <ATMButton
        key="download"
        secondary
        size="small"
        icon="download"
        floated="right"
        disabled={fileData.length === 0}
        content={Lang.LBL_DOWNLOAD}
        onClick={handleDataExport}
      />
    </div>
  );
};

export default DailyReportRadializedDownloader;
