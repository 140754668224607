import { get, sortBy } from 'lodash';
import { FieldPath, FieldValues } from 'react-hook-form';
import {
  RAW_EMPLOYEE_ID_LENGTH,
  ZERO_PADDED_EMPLOYEE_ID_LENGTH,
} from 'src/constants';
import { IUserGroup, IUserGroupMembers } from 'src/models/user-group.model';

export enum MCCSectionType {
  TROUBLE_TICKET = 'Trouble Ticket',
  OUTAGE_PLANNING = 'Outage Planning',
  GENERAL = 'General',
}

export const formatToRawEmployeeId = <T extends string | string[]>(
  empId?: T | null
) => {
  if (Array.isArray(empId)) {
    const list: string[] = Array.from(new Set(empId.filter(Boolean)));

    return list.map((id) =>
      id && id.slice(0, 2) === '00'
        ? id.substring(id.length - RAW_EMPLOYEE_ID_LENGTH)
        : id
    );
  }
  const employeeId =
    empId && (empId as string).slice(0, 2) === '00'
      ? (empId as string).substring(empId.length - RAW_EMPLOYEE_ID_LENGTH)
      : empId;

  return employeeId || '';
};

export const formatToZeroPaddedEmployeeId = (empId: string) => {
  return empId.padStart(ZERO_PADDED_EMPLOYEE_ID_LENGTH, '0');
};

let startEngineerList: IUserGroupMembers[] = [];

export const setStartEngineerList = (list: IUserGroup[] = []) => {
  if (list.length) {
    startEngineerList = (list[0].userGrpMembers ?? []).map((v) => ({
      ...v,
      empId: formatToRawEmployeeId(v.empId) as string,
    }));
  }
};

export const getStartEngineerList = () => startEngineerList;

type IEmployeeName = {
  empId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  contracShortNm?: string | null;
};

export const getEmployeeName = (
  data?: IEmployeeName | null,
  hasId = true,
  sectionType?: MCCSectionType
) => {
  let fullName = 'Unavailable';

  if (data) {
    if (data.lastName && data.firstName) {
      fullName = `${data.lastName}, ${data.firstName}, ${
        data.middleName ? data.middleName.charAt(0) : ''
      }`
        .trim()
        .replace(/,\s*$/, '');
    } else if (data.empId) {
      return data.contracShortNm
        ? data.empId
        : formatToRawEmployeeId(data.empId);
    }

    if (hasId && data.empId) {
      if (sectionType) {
        switch (sectionType) {
          case MCCSectionType.TROUBLE_TICKET: {
            fullName = `${fullName} / ${
              data.contracShortNm
                ? data.empId
                : formatToRawEmployeeId(data.empId)
            }`;
            break;
          }
          default:
            break;
        }
      } else {
        fullName = `${fullName} (${
          data.contracShortNm ? data.empId : formatToRawEmployeeId(data.empId)
        })`;
      }
    }
  }

  return fullName;
};

export const getEmployeeSort = <
  TFieldValues extends FieldValues = FieldValues,
  F extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  data: TFieldValues[],
  sort?: F,
  order: 'asc' | 'desc' = 'asc'
) => {
  const result = sortBy(data, (value) =>
    get(value, (sort || 'lastName') as any)
  );

  if (order === 'desc') {
    return result.reverse();
  }

  return result;
};
