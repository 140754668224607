import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FieldArrayWithId, useFieldArray } from 'react-hook-form';
import { get } from 'lodash';
import { v4 as uuid } from 'uuid';
import {
  ATMField,
  ATMHeader,
  ATMSegment,
  ATMDatePicker,
  ATMInput,
  ATMButton,
  ATMForm,
  ATMSelect,
  ATMIcon,
  ATMCheckbox,
  useATMFormContext,
  ATMFormProvider,
  MOLRightPanel,
} from 'shared-it-appmod-ui';
import Moment, {
  createDateTime,
  dateInEventRange,
  DATE_TIME_FORMAT,
  format24hTime,
  IMoment,
} from 'src/libraries/moment.library';
import Lang from 'src/libraries/language';
import {
  ILerRequestOutageDate,
  ILerRequestOutageFacilityListForm,
  LerRequestOutageFacilityListForm,
} from 'src/models/ler-request.model';
import { LERRequestStatus } from 'src/constants';
import { ICalendarEvent } from 'src/components/organisms/calendar/calendar.component';
import { useSettingConfig } from 'src/hooks/setting.hook';
import FormError from 'src/components/atoms/form-error/form-error.component';
import { getLerOutageDateList } from 'src/helpers/ler-request.helper';
import styles from './ler-request-outage-date-form.module.scss';

export const parseEvent = (
  v: Partial<ILerRequestOutageDate>
): ICalendarEvent => ({
  title: ({ start, end, allDay }, week) => {
    const isStart = week[0].isSameOrBefore(start, 'day');
    const isEnd = end.isAfter(week[week.length - 1], 'day');

    if (allDay) {
      return (
        <div className={styles.event}>
          <span>
            <ATMIcon name="clock outline" />
            {Lang.LBL_ALL_DAY_OUTAGE}
          </span>
        </div>
      );
    }

    if (start.isSame(end, 'date')) {
      return (
        <div className={styles.event}>
          <span>
            <ATMIcon name="clock outline" />
            {format24hTime(v.startTm)} - {format24hTime(v.stopTm)}
          </span>
        </div>
      );
    }

    return (
      <div className={styles.event}>
        {isStart ? (
          <span>
            <ATMIcon name="clock outline" /> Starts {format24hTime(v.startTm)}
          </span>
        ) : (
          <span> </span>
        )}
        {!isEnd && <span>Ends {format24hTime(v.stopTm)}</span>}
      </div>
    );
  },
  allDay: v.allDay,
  start: v.allDay ? Moment(v.startTm).startOf('day') : Moment(v.startTm),
  end: v.allDay ? Moment(v.stopTm).startOf('day') : Moment(v.stopTm),
});

const getTimeOptions = (
  hasHalftime = false,
  hasQuarterTime = false,
  start = '00:00',
  totalHours = 24
) => {
  let timesCounter = totalHours;
  let multiplier = 60;

  if (hasHalftime) {
    timesCounter = timesCounter * 2 - 1;
    multiplier = 30;
  } else if (hasQuarterTime) {
    timesCounter = timesCounter * 4 - 1;
    multiplier = 15;
  }

  const list = Array(timesCounter)
    .fill(0)
    .map((_, i) => {
      const date = Moment(start, DATE_TIME_FORMAT).add(
        i * multiplier,
        'minute'
      );

      return {
        key: date.format(DATE_TIME_FORMAT),
        value: date.format(DATE_TIME_FORMAT),
        text: `${date.format(DATE_TIME_FORMAT)}`,
      };
    });

  if (totalHours === 24) {
    list.push({
      key: '23:59',
      value: '23:59',
      text: '23:59',
    });
  }

  return list;
};

const TimePicker: React.FC<{
  name: string;
  control: any;
  defaultValue?: IMoment;
  minDate: IMoment;
  label: string;
  error: any;
  disabled: boolean;
  callback: (value?: Date | null) => void;
}> = ({
  name,
  control,
  minDate,
  defaultValue,
  label,
  disabled,
  error,
  callback,
}) => {
  const [date, setDate] = useState(defaultValue && Moment(defaultValue));
  const [time, setTime] = useState(
    defaultValue ? Moment(defaultValue).format(DATE_TIME_FORMAT) : '15:00'
  );

  return (
    <>
      <ATMField
        name={name}
        control={control}
        label={`${label} Date`}
        as={ATMDatePicker}
        minDate={minDate.toDate()}
        defaultValue={defaultValue ? Moment(defaultValue).toDate() : undefined}
        onChange={([_, { value: val }]) => {
          let dateValue = val && Moment(val);
          setDate(dateValue);

          if (dateValue) {
            dateValue = createDateTime(dateValue, time).toDate();
          }

          callback(dateValue);

          return dateValue;
        }}
        error={error}
        disabled={disabled}
      />
      <ATMForm.Field
        label={`${label} Time`}
        control={ATMSelect}
        value={time}
        error={!!error}
        options={getTimeOptions(false, true)}
        onChange={(_, { value: val }) => {
          setTime(val);

          if (date) {
            const timeValue = createDateTime(date, val).toDate();
            callback(timeValue);
          }

          return val;
        }}
        disabled={disabled}
        search
      />
    </>
  );
};

type ILERRequestOutageDateForm = {
  date: IMoment;
  fields: FieldArrayWithId<ILerRequestOutageDate>[];
  multiple?: boolean;
  handleAdd: (
    list: ILerRequestOutageFacilityListForm,
    callback: () => void
  ) => void;
  handleDelete: (key: string | string[]) => void;
  onClose: () => void;
  facilityBesInd: string;
};

const Content: React.FC<{
  formRef: React.RefObject<HTMLFormElement>;
  date: IMoment;
  multiple?: boolean;
  facilityBesInd: string;
  handleDelete: (key: string | string[]) => void;
  onClose: () => void;
}> = ({
  formRef,
  date,
  multiple = true,
  facilityBesInd,
  handleDelete,
  onClose,
}) => {
  const [startDate, setStartDate] = useState<IMoment>(
    date.clone().set({
      hour: 7,
      minute: 0,
      second: 0,
    })
  );
  const [endDate, setEndDate] = useState<IMoment>(
    date.clone().set({
      hour: 15,
      minute: 0,
      second: 0,
    })
  );
  const {
    control,
    formState: { errors },
    trigger,
    setValue,
  } = useATMFormContext<ILerRequestOutageFacilityListForm>();
  const { minOutageDate: minDate } = useSettingConfig(facilityBesInd);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'outageDatelist',
  });

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  useEffect(() => {
    if (!fields.length) {
      append({
        key: uuid(),
        startTm: startDate.toDate(),
        stopTm: endDate.toDate(),
      } as any);
    }
  }, [fields, append]);

  const [allDay, setAllDay] = useState(
    fields.length > 0 && fields.every((val) => val.allDay)
  );

  useEffect(() => {
    if (allDay && fields.length > 1) {
      const field = fields[0];
      remove();
      append(field);
    }
  }, [fields, allDay, append, remove]);

  const isAllowed =
    (!allDay && multiple) ||
    (!multiple &&
      fields.length === 1 &&
      !Moment(fields[0].startTm).isSame(date, 'day') &&
      Moment(fields[0].stopTm).isSame(date, 'day'));

  return (
    <MOLRightPanel
      isOpen
      onClose={onClose}
      className={styles.floatingPanel}
      panelFooter={
        <>
          {isAllowed && (
            <ATMButton
              secondary
              type="button"
              style={{ float: 'left' }}
              onClick={() => {
                append({
                  allDay: false,
                  reqStatId: undefined,
                  key: uuid(),
                  startTm: startDate.toDate(),
                  stopTm: endDate.toDate(),
                  updatedAt: undefined,
                  updatedBy: undefined,
                });
              }}
            >
              {fields.length > 0 ? Lang.LBL_ADD_MORE : Lang.LBL_ADD}
            </ATMButton>
          )}
          <ATMButton
            type="button"
            secondary
            onClick={() => {
              onClose();
            }}
          >
            {Lang.LBL_CANCEL}
          </ATMButton>
          <ATMButton
            type="button"
            primary
            onClick={handleClick}
            disabled={!fields.length}
          >
            {Lang.LBL_SAVE}
          </ATMButton>
        </>
      }
    >
      <div className={styles.header}>
        <ATMHeader>{date.format('MMMM DD, YYYY')}</ATMHeader>
        <div className={styles.checkbox}>
          <label>{Lang.LBL_ALL_DAY_OUTAGE}</label>
          <ATMCheckbox
            toggle
            checked={allDay}
            onChange={(_, { checked }) => setAllDay(checked || false)}
          />
        </div>
      </div>

      <div className={styles.content}>
        <div className="ui form">
          <FormError
            scrollToSelf
            errors={errors}
            dictionary="FORM_OUTAGE_DATE"
          />

          {fields.map((val: any, index: number) => (
            <ATMSegment key={val.id || index}>
              <div className={styles.contentHeader}>
                <label>{Lang.formatString(Lang.TTL_OUTAGE, index + 1)}</label>
                {fields.length > 1 && (
                  <ATMIcon
                    name="trash alternate"
                    onClick={() => {
                      remove(index);
                      if (val.key) {
                        handleDelete(val.key);
                      }
                    }}
                  />
                )}
              </div>

              <div className={styles.section}>
                <ATMField
                  name={`outageDatelist[${index}].key`}
                  control={control}
                  as={ATMInput}
                  defaultValue={val.key}
                  type="hidden"
                  className="hidden"
                />

                <ATMField
                  name={`outageDatelist[${index}].reqStatId`}
                  control={control}
                  as={ATMInput}
                  defaultValue={val.reqStatId || LERRequestStatus.Created}
                  type="hidden"
                  className="hidden"
                />

                <ATMField
                  key={`checkbox_${index}_${allDay ? 1 : 0}`}
                  name={`outageDatelist[${index}].allDay`}
                  control={control}
                  as={ATMInput}
                  defaultValue={allDay}
                  type="hidden"
                  className="hidden"
                />

                <TimePicker
                  control={control}
                  minDate={minDate}
                  name={`outageDatelist[${index}].startTm`}
                  key={`startTime_${index}_${allDay ? 1 : 0}`}
                  label="Start"
                  defaultValue={val.startTm}
                  error={get(errors, `outageDatelist[${index}].startTm`)}
                  callback={(value) => {
                    if (value) {
                      setStartDate(Moment(value));
                    }

                    setValue(`outageDatelist[${index}].startTm` as any, value, {
                      shouldValidate: true,
                    });

                    setImmediate(() => {
                      trigger();
                    });
                  }}
                  disabled={
                    val.reqStatId === LERRequestStatus.Out ||
                    allDay ||
                    (!multiple &&
                      !date.isBetween(
                        Moment(val.startTm).subtract(1, 'day').endOf('day'),
                        Moment(val.stopTm).add(1, 'day').startOf('day')
                      ))
                  }
                />
              </div>
              <div className={styles.section}>
                <TimePicker
                  control={control}
                  minDate={minDate}
                  name={`outageDatelist[${index}].stopTm`}
                  key={`endTime_${index}_${allDay ? 1 : 0}`}
                  label="End"
                  defaultValue={val.stopTm}
                  error={get(errors, `outageDatelist[${index}].stopTm`)}
                  callback={(value) => {
                    if (value) {
                      setEndDate(Moment(value));
                    }

                    setValue(`outageDatelist[${index}].stopTm` as any, value, {
                      shouldValidate: true,
                    });

                    setImmediate(() => {
                      trigger();
                    });
                  }}
                  disabled={
                    val.reqStatId === LERRequestStatus.In ||
                    allDay ||
                    (!multiple &&
                      !date.isBetween(
                        Moment(val.startTm).subtract(1, 'day').endOf('day'),
                        Moment(val.stopTm).add(1, 'day').startOf('day')
                      ))
                  }
                />
              </div>
            </ATMSegment>
          ))}
        </div>
      </div>
    </MOLRightPanel>
  );
};

const LERRequestOutageDateForm: React.FC<ILERRequestOutageDateForm> = ({
  handleAdd,
  onClose,
  fields,
  facilityBesInd,
  ...props
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const sameDayList = useMemo(() => {
    // We first merge all the continuous dates then filter that in date range
    return getLerOutageDateList(
      fields as unknown as ILerRequestOutageDate[]
    ).filter((val) => {
      const value = parseEvent(val);
      if (val.startTm) {
        return dateInEventRange({
          event: {
            start: props.date.startOf('day'),
            end: props.date.endOf('day'),
          },
          range: {
            start: value.start,
            end: value.end,
          },
        });
      }

      return false;
    });
  }, [fields]);

  return (
    <ATMForm
      ref={formRef}
      onSubmit={async (data, { setError }) => {
        const outageDatelist = getLerOutageDateList(data.outageDatelist, true);
        const keys = sameDayList.map((v) => v.key as string);

        try {
          // This will check if there are overlaps outside the form
          await LerRequestOutageFacilityListForm.validate({
            outageDatelist: [
              ...fields.filter((v: any) => !keys.flat().includes(v.key)),
              ...outageDatelist.filter(
                (v: any) => !keys.flat().includes(v.key)
              ),
            ],
          });

          handleAdd(
            {
              outageDatelist,
            } as ILerRequestOutageFacilityListForm,
            () => {
              props.handleDelete(keys);
              onClose();
            }
          );
        } catch (e: any) {
          setError('outageDatelist', {
            message: e.message,
          });
        }
      }}
      defaultValues={LerRequestOutageFacilityListForm.cast({
        outageDatelist: sameDayList,
      })}
      validationSchema={LerRequestOutageFacilityListForm}
      mode="onChange"
    >
      {(formProps) => {
        return (
          <ATMFormProvider {...formProps}>
            <Content
              {...props}
              formRef={formRef}
              onClose={onClose}
              facilityBesInd={facilityBesInd}
            />
          </ATMFormProvider>
        );
      }}
    </ATMForm>
  );
};

export default LERRequestOutageDateForm;
