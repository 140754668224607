import { UseFormReturn } from 'react-hook-form';
import yup from 'src/libraries/validator.library';

export const SuccessSchema = yup.object({ success: yup.number() }).defined();

export const MessageSchema = yup
  .object()
  .shape({ message: yup.number() })
  .defined();

export type IFormMethods<T> = Pick<
  UseFormReturn<T>,
  'control' | 'formState' | 'watch'
> &
  Partial<Pick<UseFormReturn<T>, 'setError' | 'clearErrors' | 'formState'>>;
