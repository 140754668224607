import { HourTimePattern } from 'src/components/atoms/input/time-input-switching-format.component';
import * as yup from 'yup';
import { TestContext } from 'yup';

export const SwitchingShape = {
  switchId: yup.string().required(),
  outgFacId: yup.number().required(),
  description: yup.string().required(),
  poleId: yup.string().required(),
  subDistId: yup.string().required(),
  updatedAt: yup.date().required(),
  updatedBy: yup.string().required(),
};

export const SortResponseShape = {
  sortId: yup.string().required(),
  outBack: yup.string().required(),
  sortTime: yup.date().nullable(),
  dspchRem: yup.string().nullable().default(' '),
  sortStat: yup.string().nullable(),
  etmNbr: yup.number().required(),
  byAt: yup.string().required(),
  tlSub: yup.string().required(),
  subPole: yup.string().required(),
  jobKind: yup.string().required(),
  jobNbr: yup.string().nullable(),
  jobCd: yup.string().nullable(),
  subDistId: yup.string().required(),
  outgFacId: yup.number().nullable(),
  switchId: yup.string().nullable(),
  poleId: yup.string().nullable(),
  substationId: yup.string().nullable(),
  troubleJobId: yup.number().nullable(),
  requestId: yup.number().nullable(),
  createdAt: yup.date().nullable(),
  createdBy: yup.string().nullable(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
  cancellation: yup.boolean().notRequired(),
  sent: yup.boolean(),
  cancel: yup.boolean(),
  time: yup.string().nullable(),
  date: yup.date().nullable(),
};

export const checkDateTime = (_value, context: TestContext) => {
  const {
    path,
    parent: { isChecked, outDate, outTime, backDate, backTime },
  } = context;
  if (isChecked) {
    if (outDate && (outTime === null || outTime === undefined)) {
      if (path.includes('outTime')) {
        return context.createError({
          message: 'Please enter time',
        });
      }
    }
    if (outTime) {
      if (!outTime.match(HourTimePattern.HOUR_TIME_PATTERN as any)) {
        if (path.includes('outTime')) {
          return context.createError({
            message: 'Invalid Time',
          });
        }
      }
    }

    if (backTime) {
      if (!backTime.match(HourTimePattern.HOUR_TIME_PATTERN as any)) {
        if (path.includes('backTime')) {
          return context.createError({
            message: 'Invalid Time',
          });
        }
      }
    }

    if (!outDate && outTime) {
      if (path.includes('outDate')) {
        return context.createError({
          message: 'Please select a date',
        });
      }
    }
    if (backDate && (backTime === null || backTime === undefined)) {
      if (path.includes('backTime')) {
        return context.createError({
          message: 'Please enter time',
        });
      }
    }
    if (!backDate && backTime) {
      if (path.includes('backDate')) {
        return context.createError({
          message: 'Please select a date',
        });
      }
    }
  }
  return true;
};

export const EtsFormDispatchShape = {
  isChecked: yup.boolean().required().default(false),
  out: yup
    .object({
      byAt: yup.string(),
      sortTime: yup.date(),
    })
    .when('isChecked', {
      is: (value) => Boolean(value) === true,
      then: yup.object({
        byAt: yup.string().required(),
        sortTime: yup.date(),
      }),
    }),
  back: yup
    .object({
      byAt: yup.string(),
      sortTime: yup.date(),
    })
    .when('isChecked', {
      is: (value) => Boolean(value) === true,
      then: yup.object({
        byAt: yup.string().required(),
        sortTime: yup.date(),
      }),
    }),
  etmNbr: yup.number().required(),
  tlSub: yup
    .string()
    .nullable()
    .when('isChecked', {
      is: (value) => Boolean(value) === true,
      then: yup.string().required(),
    }),
  subPole: yup
    .string()
    .nullable()
    .when('isChecked', {
      is: (value) => Boolean(value) === true,
      then: yup.string().required(),
    }),
  outgFacId: yup.number().nullable(),
  switchId: yup.string().nullable(),
  substationId: yup.string().nullable(),
  poleId: yup.string().nullable(),
  subDistId: yup
    .string()
    .nullable()
    .when('isChecked', {
      is: (value) => Boolean(value) === true,
      then: yup.string().required(),
    }),
  outDate: yup.date().nullable().test(checkDateTime),
  outTime: yup.string().nullable().default(undefined).test(checkDateTime),
  backDate: yup.date().nullable().test(checkDateTime),
  backTime: yup.string().nullable().default(undefined).test(checkDateTime),
};

export const EtsFormDispatchSchema = yup.object({
  etsDispatch: yup.array().of(yup.object(EtsFormDispatchShape)).required(),
});

export const EtsDispatchSchema = yup.object(EtsFormDispatchShape);

export const SortResponseSchema = yup.object(SortResponseShape);

export const SwitchingCreateSchema = yup
  .array()
  .of(yup.object(EtsFormDispatchShape))
  .required();
export const SwitchingCreatePayloadSchema = yup.array().of(SortResponseSchema);
export const SwitchingSchema = yup.object(SwitchingShape);
export const SwitchingPayloadSchema = SwitchingSchema;
export const SwitchingListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(SwitchingSchema.clone()),
});

export type ISwitching = yup.Asserts<typeof SwitchingSchema>;
export type ISwitchingCreate = yup.Asserts<typeof SwitchingCreateSchema>;
export type ISortResponse = yup.Asserts<typeof SortResponseSchema>;
export type IEtsDispatch = yup.Asserts<typeof EtsDispatchSchema>;
export type ISwitchingCreatePayload = yup.Asserts<
  typeof SwitchingCreatePayloadSchema
>;
export type IEtsFormDispatch = yup.Asserts<typeof EtsFormDispatchSchema>;
